import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useContext, useEffect, useMemo, useState } from "react";
import { Badge } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Table from "./Table";
import Layout from "../../Layout";
import AdminContext from "../../Services/Admin";
import moment from "moment";

const schema = yup
  .object({
    name: yup.string().required(),
  })
  .required();

function TransactionFlightPage(params) {
  const { addMaster, loading } = useContext(AdminContext);
  const [data, setData] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      limit: 9999,
      startDate: moment().subtract(1, 'W').toDate(),
      endDate: moment().add(1, 'W').toDate(),
    },
  });
  const {
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const getParams = (params) => ({
    search: params.pagination?.search,
    startDate: params.pagination?.startDate,
    endDate: params.pagination?.endDate,
    page: params.pagination?.current,
    limit: params.pagination?.limit,
    sort: params.sorter?.field,
    direction: params.sorter?.order === "descend" ? "DESC" : "ASC",
  });

  const typeStatus = (status) => {
    let color = "light";
    if (status === "pending" || status === "waiting_payment" || status === "capture") {
      color = "warning";
    } else if (status === "settlement") {
      color = "success";
    } else if (
      status === "cancel" ||
      status === "expire" ||
      status === "deny"
    ) {
      color = "danger";
    }

    return color;
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "invoiceNumber",
        header: "Order Code",
      },
      {
        accessorKey: "user",
        header: "Full Name",
        Cell: ({ cell }) =>
          cell.getValue()?.firstName + " " + cell.getValue()?.lastName,
      },
      {
        accessorKey: "user.email",
        header: "Email",
      },
      {
        accessorKey: "grossAmount",
        header: "Total Payment",
        Cell: ({ cell }) => {
          return new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 0,
          }).format(cell.getValue());
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ cell }) => {
          return (
            <Badge bg={typeStatus(cell.getValue())}>
              {cell.getValue().toUpperCase()}
            </Badge>
          );
        },
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
        Cell: ({ cell }) =>
          moment(cell.getValue()).locale("id").format("DD/MM/YYYY HH:mm"),
      },
    ],
    []
  );

  const getData = async () => {
    const customParams = getParams(tableParams);
    const newData = await addMaster(
      `voltras/got-orders?page=${customParams.page}&limit=${customParams.limit}`,
      {
        statusPayment: tableParams.status || "",
        search: customParams.search || "",
        startDate: customParams.startDate || "",
        endDate: customParams.endDate || "",
      }
    );
    if (newData) {
      setData(newData.data);
    }
  };

  useEffect(() => {
    getData();
  }, [
    tableParams
  ]);

  return (
    <Layout>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="nav-align-top mb-4">
          <ul
            className="nav nav-pills mb-3 bg-white p-2 rounded shadow-sm"
            role="tablist"
          >
            <li
              className="nav-item"
              onClick={() =>
                setTableParams({
                  ...tableParams,
                  status: "",
                })
              }
            >
              <button
                type="button"
                className="nav-link active"
                role="tab"
                data-bs-toggle="tab"
              >
                All
              </button>
            </li>
            <li
              className="nav-item"
              onClick={() =>
                setTableParams({
                  ...tableParams,
                  status: "settlement",
                })
              }
            >
              <button
                type="button"
                className="nav-link"
                role="tab"
                data-bs-toggle="tab"
              >
                Settlement
              </button>
            </li>
            <li
              className="nav-item"
              onClick={() =>
                setTableParams({
                  ...tableParams,
                  status: "pending",
                })
              }
            >
              <button
                type="button"
                className="nav-link"
                role="tab"
                data-bs-toggle="tab"
              >
                Pending
              </button>
            </li>
            <li
              className="nav-item"
              onClick={() =>
                setTableParams({
                  ...tableParams,
                  status: "waiting_payment",
                })
              }
            >
              <button
                type="button"
                className="nav-link"
                role="tab"
                data-bs-toggle="tab"
              >
                Waiting Payment
              </button>
            </li>
            <li
              className="nav-item"
              onClick={() =>
                setTableParams({
                  ...tableParams,
                  status: "cancel",
                })
              }
            >
              <button
                type="button"
                className="nav-link"
                role="tab"
                data-bs-toggle="tab"
              >
                Cancelled
              </button>
            </li>
            <li
              className="nav-item"
              onClick={() =>
                setTableParams({
                  ...tableParams,
                  status: "expire",
                })
              }
            >
              <button
                type="button"
                className="nav-link"
                role="tab"
                data-bs-toggle="tab"
              >
                Expired
              </button>
            </li>
            <li
              className="nav-item"
              onClick={() =>
                setTableParams({
                  ...tableParams,
                  status: "deny",
                })
              }
            >
              <button
                type="button"
                className="nav-link"
                role="tab"
                data-bs-toggle="tab"
              >
                Deny
              </button>
            </li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane fade show active" role="tabpanel">
              <Table setTableParams={setTableParams} tableParams={tableParams} columns={columns} data={data} loading={loading} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default TransactionFlightPage;
