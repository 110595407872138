import { useContext, useEffect, useState } from "react";
import { Button, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../Services/Auth";

function Sidebar(params) {
  const [showMenu, setShowMenu] = useState("");
  const [activeMenu, setActiveMenu] = useState("");
  const [activeSubMenu, setActiveSubMenu] = useState("");
  const { pathname } = useLocation();
  let navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  useEffect(() => {
    const newPathArray = pathname.split("/");
    if (newPathArray.hasOwnProperty(1)) {
      setActiveMenu(newPathArray[1]);
      setShowMenu(newPathArray[1]);
    }
    if (newPathArray.hasOwnProperty(2)) {
      setActiveSubMenu(newPathArray[2]);
    }
  }, [pathname]);

  return (
    <aside
      id="layout-menu"
      className="layout-menu menu-vertical menu bg-menu-theme"
    >
      <div className="app-brand demo">
        <Link to="/" className="app-brand-link">
          <span className="app-brand-logo demo">
            <Image src="/assets/img/pigijo-logo.svg" />
          </span>
        </Link>

        <div
          onClick={() => params.setToogle(false)}
          className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none"
        >
          <i className="bx bx-chevron-left bx-sm align-middle"></i>
        </div>
      </div>

      <div className="menu-inner-shadow"></div>

      <ul className="menu-inner py-1">
        <li className={`menu-item ${activeMenu === "dashboard" && "active"}`}>
          <Link to="/" className="menu-link">
            <i className="menu-icon tf-icons bx bx-home-circle"></i>
            <div>Dashboard</div>
          </Link>
        </li>

        <li className="menu-header small text-uppercase">
          <span className="menu-header-text">Menu</span>
        </li>

        <li
          className={`menu-item ${showMenu === "local-experience" && "open"} ${
            activeMenu === "local-experience" && "active"
          }`}
        >
          <div
            role="button"
            onClick={() =>
              setShowMenu(
                showMenu === "local-experience" ? "" : "local-experience"
              )
            }
            className="menu-link menu-toggle"
          >
            <i className="menu-icon tf-icons bx bx-layout"></i>
            <div>Local Experience</div>
          </div>

          <ul className="menu-sub">
            <li
              className={`menu-item ${
                activeSubMenu === "products" && "active"
              }`}
            >
              <Link to="/local-experience/products" className="menu-link">
                <div>Product</div>
              </Link>
            </li>
            {(localStorage.getItem("role") === "admin" ||
              localStorage.getItem("role") === "super admin") && (
              <li
                className={`menu-item ${
                  activeSubMenu === "product-category" && "active"
                }`}
              >
                <Link
                  to="/local-experience/product-category"
                  className="menu-link"
                >
                  <div>Product Category</div>
                </Link>
              </li>
            )}
          </ul>
        </li>

        <li
          className={`menu-item ${showMenu === "transaction" && "open"} ${
            activeMenu === "transaction" && "active"
          }`}
        >
          <div
            role="button"
            onClick={() =>
              setShowMenu(showMenu === "transaction" ? "" : "transaction")
            }
            className="menu-link menu-toggle"
          >
            {" "}
            <i className="menu-icon tf-icons bx bx-cart"></i>
            <div>Transaction</div>
          </div>

          <ul className="menu-sub">
            <li
              className={`menu-item ${
                activeSubMenu === "local-experience" && "active"
              }`}
            >
              <Link to="/transaction/local-experience" className="menu-link">
                <div>Local Experience</div>
              </Link>
            </li>
            {(localStorage.getItem("role") === "admin" ||
              localStorage.getItem("role") === "super admin") && (
              <li
                className={`menu-item ${
                  activeSubMenu === "flight" && "active"
                }`}
              >
                <Link to="/transaction/flight" className="menu-link">
                  <div>Flight</div>
                </Link>
              </li>
            )}
            {(localStorage.getItem("role") === "admin" ||
              localStorage.getItem("role") === "super admin") && (
              <li
                className={`menu-item ${
                  activeSubMenu === "voucher" && "active"
                }`}
              >
                <Link to="/transaction/voucher" className="menu-link">
                  <div>Voucher</div>
                </Link>
              </li>
            )}
          </ul>
        </li>

        {(localStorage.getItem("role") === "admin" ||
          localStorage.getItem("role") === "super admin") && (
          <>
            <li className="menu-header small text-uppercase">
              <span className="menu-header-text">System Control</span>
            </li>
            <li
              className={`menu-item ${showMenu === "settings-user" && "open"} ${
                activeMenu === "settings-user" && "active"
              }`}
            >
              <div
                role="button"
                onClick={() =>
                  setShowMenu(
                    showMenu === "settings-user" ? "" : "settings-user"
                  )
                }
                className="menu-link menu-toggle"
              >
                <i className="menu-icon tf-icons bx bx-group"></i>
                <div>Users</div>
              </div>

              <ul className="menu-sub">
                <li
                  className={`menu-item ${
                    activeSubMenu === "admin" && "active"
                  }`}
                >
                  <Link to="/settings-user/admin" className="menu-link">
                    <div>Admin</div>
                  </Link>
                </li>
                <li
                  className={`menu-item ${
                    activeSubMenu === "member" && "active"
                  }`}
                >
                  <Link to="/settings-user/member" className="menu-link">
                    <div>Member</div>
                  </Link>
                </li>
                <li
                  className={`menu-item ${
                    activeSubMenu === "partners" && "active"
                  }`}
                >
                  <Link to="/settings-user/partners" className="menu-link">
                    <div>Partner</div>
                  </Link>
                </li>
                <li
                  className={`menu-item ${
                    activeSubMenu === "travel-assistance" && "active"
                  }`}
                >
                  <Link
                    to="/settings-user/travel-assistance"
                    className="menu-link"
                  >
                    <div>Travel Assistance</div>
                  </Link>
                </li>
              </ul>
            </li>

            <li
              className={`menu-item ${
                showMenu === "settings-website" && "open"
              } ${activeMenu === "settings-website" && "active"}`}
            >
              <div
                role="button"
                onClick={() =>
                  setShowMenu(
                    showMenu === "settings-website" ? "" : "settings-website"
                  )
                }
                className="menu-link menu-toggle"
              >
                <i className="menu-icon tf-icons bx bx-globe"></i>
                <div>Website Settings</div>
              </div>

              <ul className="menu-sub">
                <li
                  className={`menu-item ${
                    activeSubMenu === "partners" && "active"
                  }`}
                >
                  <Link to="/settings-website/partners" className="menu-link">
                    <div>Partners</div>
                  </Link>
                </li>
                <li
                  className={`menu-item ${
                    activeSubMenu === "partner-category" && "active"
                  }`}
                >
                  <Link
                    to="/settings-website/partner-category"
                    className="menu-link"
                  >
                    <div>Partners Category</div>
                  </Link>
                </li>
                <li
                  className={`menu-item ${
                    activeSubMenu === "banner" && "active"
                  }`}
                >
                  <Link to="/settings-website/banner" className="menu-link">
                    <div>Banner</div>
                  </Link>
                </li>
                <li
                  className={`menu-item ${
                    activeSubMenu === "destination" && "active"
                  }`}
                >
                  <Link
                    to="/settings-website/destination"
                    className="menu-link"
                  >
                    <div>Menu Destination</div>
                  </Link>
                </li>
                <li
                  className={`menu-item ${
                    activeSubMenu === "menu-recomendation" && "active"
                  }`}
                >
                  <Link
                    to="/settings-website/menu-recomendation"
                    className="menu-link"
                  >
                    <div>Menu Recomendation LE</div>
                  </Link>
                </li>
                <li
                  className={`menu-item ${
                    activeSubMenu === "menu-footer" && "active"
                  }`}
                >
                  <Link
                    to="/settings-website/menu-footer"
                    className="menu-link"
                  >
                    <div>Menu Footer LE</div>
                  </Link>
                </li>
              </ul>
            </li>
            {/* <li className="menu-item">
              <a href="cards-basic.html" className="menu-link">
                <i className="menu-icon tf-icons bx bx-wrench"></i>
                <div data-i18n="Basic">Configuration</div>
              </a>
            </li>
            <li className="menu-item">
              <a href="cards-basic.html" className="menu-link">
                <i className="menu-icon tf-icons bx bx-file"></i>
                <div data-i18n="Basic">Log</div>
              </a>
            </li> */}
          </>
        )}
        <li className="menu-header small text-uppercase">
          <span className="menu-header-text">Profile</span>
        </li>
        <li className={`menu-item ${activeMenu === "profile" && "active"}`}>
          <Link to="/profile" className="menu-link">
            <i className="menu-icon tf-icons bx bx-face"></i>
            <div data-i18n="Basic">Profile</div>
          </Link>
        </li>
      </ul>
      <div className="mb-5 text-center">
        <Button
          className="w-75"
          onClick={() => {
            if (
              localStorage.getItem("role") === "admin" ||
              localStorage.getItem("role") === "super admin"
            ) {
              navigate("/login");
            } else {
              navigate("/login-partner");
            }
            logout();
          }}
        >
          Logout
        </Button>
      </div>
    </aside>
  );
}

export default Sidebar;
