import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useContext, useEffect, useMemo, useState } from "react";
import { Badge, Col, Image } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import Table from "./Table";
import Layout from "../../Layout";
import AdminContext from "../../Services/Admin";
import moment from "moment";
import WYSIWYGEditor from "../Products/Components/Wysiwyg";
import CityForm from "./Components/CityForm";

const schema = yup
  .object({
    caption: yup.string().required(),
    description: yup.string().required(),
    city: yup.array().of(yup.object()).min(1),
  })
  .required();

function DestinationPage(params) {
  const { loading, getMaster, getAllMaster, addMaster, addMasterForm } =
    useContext(AdminContext);
  const [data, setData] = useState([]);
  const [dataProvince, setDataProvince] = useState([]);
  const [provinceName, setProvinceName] = useState([]);
  const [cityName, setCityName] = useState([]);
  const [dataCity, setDataCity] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      limit: 9999,
    },
  });
  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const getParams = (params) => ({
    search: params.pagination?.search,
    page: params.pagination?.current,
    limit: params.pagination?.limit,
    sort: params.sorter?.field,
    direction: params.sorter?.order === "descend" ? "DESC" : "ASC",
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "caption",
        header: "Name",
      },
      {
        accessorKey: "city",
        header: "City",
        Cell: ({ cell }) =>
          cell?.getValue()?.length > 0 &&
          cell
            .getValue()
            .map(
              (item, index) =>
                item.city + (index < cell.getValue()?.length - 1 ? ", " : "")
            ),
      },
      {
        accessorKey: "picture",
        header: "Picture",
        Cell: ({ cell }) => (
          <Image
            src={`${process.env.REACT_APP_FILE_URL}/${cell.getValue()}`}
            className="img-table"
          />
        ),
      },
      {
        accessorKey: "isActive",
        header: "Status",
        Cell: ({ cell }) => {
          let status = "Non Active";
          if (cell.getValue() === true) {
            status = "Active";
          }
          return (
            <Badge bg={status === "Active" ? "success" : "danger"}>
              {status}
            </Badge>
          );
        },
      },
      {
        accessorKey: "updatedAt",
        header: "Last Updated",
        Cell: ({ cell }) => moment(cell.getValue()).locale("id").format("LL"),
      },
    ],
    []
  );

  const bodyForm = () => {
    return (
      <>
        <div className="mb-3">
          <label htmlFor="caption" className="form-label">
            Destination Name
          </label>
          <input
            type="text"
            id="caption"
            className="form-control"
            {...register("caption")}
            placeholder="Enter your destination name"
          />
          <small className="text-danger">{errors.caption?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="picture" className="form-label">
            Picture
          </label>
          <input
            type="file"
            id="picture"
            className="form-control"
            {...register("picture")}
            accept="image/*"
          />
          <small className="text-danger">{errors.picture?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Description
          </label>
          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <WYSIWYGEditor onChange={field.onChange} value={field.value} />
            )}
          />
          <small className="text-danger">{errors.description?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="status" className="form-label">
            Status
          </label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="status"
              value="true"
              style={{ minWidth: "50px" }}
              {...register("isActive")}
            />
            <label className="form-check-label small ps-2" htmlFor="status">
              Active {getValues("isActive")}
            </label>
          </div>
          <small className="text-danger">{errors.status?.message}</small>
        </div>
        <hr></hr>
        <div className="mb-3">
          <label htmlFor="province" className="form-label">
            Province
          </label>
          <Controller
            control={control}
            name="provinceId"
            render={({ field: { onChange, value, ref } }) => (
              <Select
                ref={ref}
                value={dataProvince.find((c) => c.value === value)}
                onChange={(val) => {
                  setProvinceName(val.label);
                  onChange(val.value);
                }}
                options={dataProvince}
                className="form-control p-0 border-0"
              />
            )}
          />
          <small className="text-danger">
            {errors?.provinceId?.message}
          </small>
        </div>
        <div className="mb-3">
          <CityForm
            {...{
              control,
              errors,
              dataCity,
              setCityName,
            }}
          />
          <small className="text-danger">{errors.city?.message}</small>
        </div>
      </>
    );
  };

  const getDataProvince = async () => {
    const newData = await getAllMaster(`address/province`);
    if (newData) {
      let dataProvinces = [
        {
          value: "",
          label: "- Select Province -",
        },
      ];
      dataProvinces.push(
        ...newData?.data?.map((item) => ({
          value: item.province_id,
          label: item.province,
        }))
      );

      setDataProvince(dataProvinces);
    }
  };

  const getDataCity = async (province_id) => {
    const newData = await getAllMaster(
      `address/city?provinceId=${province_id}`
    );
    if (newData) {
      let dataCitys = [
        {
          value: "",
          label: "- Select City -",
        },
      ];

      dataCitys.push(
        ...newData?.data?.map((item) => ({
          value: item.city_id,
          label: item.city_name,
        }))
      );

      setDataCity(dataCitys);
    }
  };

  const getData = async () => {
    const newData = await getAllMaster(
      "menu/destinations/adp",
      getParams(tableParams)
    );
    if (newData) {
      setData(newData.data);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: newData?.data?.length,
        },
      });
    }
  };

  useEffect(() => {
    getData();
  }, [
    tableParams.sorter,
    tableParams.pagination.search,
    tableParams.pagination.limit,
    tableParams.pagination.page,
  ]);

  useEffect(() => {
    getDataProvince();
  }, []);

  useEffect(() => {
    watch((value, { name, type }) => {
      if (name === "provinceId" && type === "change") {
        getDataCity(value?.provinceId);
        reset({
          caption:value?.caption,
          image:value?.image,
          description:value?.description,
          isActive:value?.isActive,
          city:[],
        });
      }
    });
  }, [watch]);

  return (
    <Layout>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="card">
          <Table
            columns={columns}
            data={data}
            getData={getData}
            getAllAction={getAllMaster}
            getAction={getMaster}
            addAction={addMaster}
            addActionForm={addMasterForm}
            bodyForm={bodyForm}
            handleSubmit={handleSubmit}
            reset={reset}
            loading={loading}
            cityName={cityName}
            setCityName={setCityName}
            provinceName={provinceName}
            setProvinceName={setProvinceName}
            getDataCity={getDataCity}
          />
        </div>
      </div>
    </Layout>
  );
}

export default DestinationPage;
