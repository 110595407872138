import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./Config/PrivateRoute";
import LoginPage from "./Pages/Login";
import AuthState from "./Services/Auth/state.js";
import AdminState from "./Services/Admin/state.js";
import { ToastContainer } from "react-toastify";
import AdminPage from "./Pages/Admin";
import ProductCategoryPage from "./Pages/ProductCategory";
import PartnerCategoryPage from "./Pages/PartnerCategory";
import PartnersPage from "./Pages/Partners";
import ProductsPage from "./Pages/Products";
import MemberUserPage from "./Pages/MemberUser";
import PartnersUserPage from "./Pages/PartnersUser";
import TravelAssistanceUserPage from "./Pages/TravelAssistanceUser";
import BannerPage from "./Pages/Banner";
import TransactionLocalExperiencePage from "./Pages/TransactionLocalExperience";
import TransactionLocalExperienceDetailPage from "./Pages/TransactionLocalExperienceDetail";
import ProfilePage from "./Pages/Profile";
import TransactionFlightPage from "./Pages/TransactionFlight";
import DestinationPage from "./Pages/Destination";
import MenuFooterPage from "./Pages/MenuFooter";
import MenuRecomendationPage from "./Pages/MenuRecomendation";
import VoucherPage from "./Pages/Voucher/index.js";

function App() {
  return (
    <AuthState>
      <AdminState>
        <BrowserRouter>
          <Routes>
            <Route index element={<LoginPage type="admin"/>} />
            <Route path="login" element={<LoginPage type="admin" />} />
            <Route path="login-partner" element={<LoginPage type="partner" />} />
            <Route element={<PrivateRoute />}>
              <Route
                path="local-experience/product-category"
                element={<ProductCategoryPage />}
              />
              <Route
                path="local-experience/products"
                element={<ProductsPage />}
              />
              <Route
                path="transaction/local-experience"
                element={<TransactionLocalExperiencePage />}
              />
              <Route
                path="transaction/local-experience/detail/:id"
                element={<TransactionLocalExperienceDetailPage />}
              />
              <Route
                path="transaction/flight"
                element={<TransactionFlightPage />}
              />
              <Route
                path="transaction/voucher"
                element={<VoucherPage />}
              />
              <Route path="settings-user/admin" element={<AdminPage />} />
              <Route path="settings-user/member" element={<MemberUserPage />} />
              <Route path="settings-user/partners" element={<PartnersUserPage />} />
              <Route path="settings-user/travel-assistance" element={<TravelAssistanceUserPage />} />
              <Route path="settings-website/partners" element={<PartnersPage />} />
              <Route path="settings-website/partner-category" element={<PartnerCategoryPage />} />
              <Route path="settings-website/banner" element={<BannerPage />} />
              <Route path="settings-website/destination" element={<DestinationPage />} />
              <Route path="settings-website/menu-footer" element={<MenuFooterPage />} />
              <Route path="settings-website/menu-recomendation" element={<MenuRecomendationPage />} />
              <Route path="profile" element={<ProfilePage />} />
            </Route>
            <Route path="*" element={<p>There's nothing here: 404!</p>} />
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </AdminState>
    </AuthState>
  );
}

export default App;
