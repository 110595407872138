import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useContext, useEffect, useMemo, useState } from "react";
import { Badge } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Table from "./Table";
import Layout from "../../Layout";
import AdminContext from "../../Services/Admin";

const schema = yup
  .object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    salutation: yup.string(),
    role: yup.string(),
    countryCode: yup.string(),
    phone: yup.string(),
    email: yup.string().email().required(),
    password: yup.string().min(5).required(),
    retypePassword: yup.string()
     .oneOf([yup.ref('password'), null], 'Passwords must match')
  })
  .required();

function AdminPage(params) {
  const {
    getAllMaster,
    loading,
    getMaster,
    addMaster,
    updateMaster,
    deleteMaster,
  } = useContext(AdminContext);
  const [data, setData] = useState([]);
  const [dataRole, setDataRole] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      page: 1,
      limit: 9999,
    },
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const getParams = (params) => ({
    search: params.pagination?.search,
    page: params.pagination?.page - 1,
    limit: params.pagination?.limit,
    sort: params.sorter?.field,
    direction: params.sorter?.order === "descend" ? "DESC" : "ASC",
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "fullName",
        header: "Full Name",
        Cell: ({ cell }) => cell?.row?.original?.firstName+" "+cell?.row?.original?.lastName,
      },
      {
        accessorKey: "role",
        header: "Role",
      },
      {
        accessorKey: "contact.email",
        header: "Email",
      },
      {
        accessorKey: "contact.phone",
        header: "Phone Number",
      },
      {
        accessorKey: "isApproved",
        header: "Status",
        Cell: ({ cell }) => {
          let status = "Non Active";
          if (cell.getValue() === true) {
            status = "Active";
          }
          return (
            <Badge bg={status === "Active" ? "success" : "danger"}>
              {status}
            </Badge>
          );
        },
      },
    ],
    []
  );

  const bodyForm = () => {
    return (
      <>
        <div className="mb-3">
          <label htmlFor="salutation" className="form-label">
            Salutation
          </label>
          <select
            id="salutation"
            className="form-select"
            {...register("salutation")}
          >
            <option value="">- Select Salutation -</option>
            <option value="Mr">Mr</option>
            <option value="Mrs">Mrs</option>
            <option value="Ms">Ms</option>
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="firstName" className="form-label">
            First Name
          </label>
          <input
            type="text"
            id="firstName"
            className="form-control"
            {...register("firstName")}
            placeholder="Enter your first name"
            autoFocus
          />
          <small className="text-danger">{errors.firstName?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="lastName" className="form-label">
            Last Name
          </label>
          <input
            type="text"
            id="lastName"
            className="form-control"
            {...register("lastName")}
            placeholder="Enter your last name"
          />
          <small className="text-danger">{errors.lastName?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="text"
            id="email"
            className="form-control"
            {...register("email")}
            placeholder="Enter your email"
          />
          <small className="text-danger">{errors.email?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="phone" className="form-label">
            Phone Number
          </label>
          <input
            type="text"
            id="phone"
            className="form-control"
            {...register("phone")}
            placeholder="Enter your phone number"
          />
          <small className="text-danger">{errors.phone?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="countryCode" className="form-label">
            Country Code
          </label>
          <select id="countryCode" className="form-select" {...register("countryCode")}>
            <option value="">- Select Country Code -</option>
            <option value="62">+62 (Indonesia)</option>
          </select>
          <small className="text-danger">{errors.countryCode?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="role" className="form-label">
            Role
          </label>
          <select id="role" className="form-select" {...register("role")}>
            <option value="">- Select Role -</option>
            <option value="super admin">Super Admin</option>
          </select>
          <small className="text-danger">{errors.role?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            type="password"
            id="password"
            className="form-control"
            {...register("password")}
            placeholder="Enter your password"
          />
          <small className="text-danger">{errors.password?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="retypePassword" className="form-label">
            Retype Password
          </label>
          <input
            type="password"
            id="retypePassword"
            className="form-control"
            {...register("retypePassword")}
            placeholder="Retype your password"
          />
          <small className="text-danger">{errors.retypePassword?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="status" className="form-label">
            Status
          </label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="status"
              value="true"
            style={{minWidth:"50px"}}
              {...register("isApproved")}
            />
            <label className="form-check-label small ps-2" htmlFor="status">
              Active
            </label>
          </div>
          <small className="text-danger">{errors.status?.message}</small>
        </div>
      </>
    );
  };

  const getData = async () => {
    const newData = await getAllMaster("admin", getParams(tableParams));
    if (newData) {
      setData(newData.data);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: newData?.data?.length,
        },
      });
    }
  };

  useEffect(() => {
    getData();
  }, [
    tableParams.sorter,
    tableParams.pagination.search,
    tableParams.pagination.limit,
    tableParams.pagination.page,
  ]);

  return (
    <Layout>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="card">
          <Table
            columns={columns}
            data={data}
            getData={getData}
            deleteAction={deleteMaster}
            addAction={addMaster}
            updateAction={updateMaster}
            bodyForm={bodyForm}
            handleSubmit={handleSubmit}
            reset={reset}
            loading={loading}
          />
        </div>
      </div>
    </Layout>
  );
}

export default AdminPage;
