import MaterialReactTable from "material-react-table";
import { Button, Stack } from "react-bootstrap";
import { TravelExplore } from "@mui/icons-material";
import ReactDatePicker from "react-datepicker";
import { Link } from "react-router-dom";

function Table(params) {
  console.log(params.tableParams?.pagination?.startDate);
  return (
    <>
      <MaterialReactTable
        muiTablePaperProps={{
          sx: {
            boxShadow: "none",
            borderRadius: "1rem",
          },
        }}
        muiTopToolbarProps={{
          sx: {
            borderRadius: "1rem",
          },
        }}
        muiBottomToolbarProps={{
          sx: {
            borderRadius: "1rem",
          },
        }}
        enableRowActions
        positionActionsColumn={"last"}
        renderRowActions={(row, index) => (
          <Button
            as={Link}
            to={`/transaction/local-experience/detail/${row?.row?.id}`}
            size="sm"
            variant="primary"
            className="py-1 px-2"
          >
            <TravelExplore sx={{ fontSize: 14 }} />
          </Button>
        )}
        state={{
          isLoading: params.loading,
        }}
        options={{
          maxBodyHeight: 400,
          headerStyle: {
            zIndex: 8,
          },
        }}
        getRowId={(originalRow) => originalRow._id}
        renderTopToolbarCustomActions={({ table }) => (
          <Stack direction="horizontal">
            <div>
              <label className="form-label">Start Date</label>
              <ReactDatePicker
                portalId="root"
                placeholderText="Select start date"
                onChange={(date) =>
                  params.setTableParams({
                    ...params.tableParams,
                    pagination: {
                      ...params.tableParams.pagination,
                      startDate: date,
                    },
                  })
                }
                selected={params.tableParams?.pagination?.startDate}
                dateFormat="dd/MM/yyyy"
                selectsStart
                startDate={params.tableParams?.pagination?.startDate}
                endDate={params.tableParams?.pagination?.endDate}
                className="form-control"
              />
            </div>
            <div className="px-3 pt-4">-</div>
            <div>
              <label className="form-label">End Date</label>
              <ReactDatePicker
                portalId="root"
                placeholderText="Select end date"
                onChange={(date) =>
                  params.setTableParams({
                    ...params.tableParams,
                    pagination: {
                      ...params.tableParams.pagination,
                      endDate: date,
                    },
                  })
                }
                selected={params.tableParams?.pagination?.endDate}
                dateFormat="dd/MM/yyyy"
                selectsEnd
                startDate={params.tableParams?.pagination?.startDate}
                endDate={params.tableParams?.pagination?.endDate}
                className="form-control"
              />
            </div>
          </Stack>
        )}
        columns={params.columns}
        data={params.data}
      />
    </>
  );
}

export default Table;
