function Footer(params) {
  return (
    <footer className="content-footer footer bg-footer-theme">
      <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
        <div className="mb-2 mb-md-0">
          ©{new Date().getFullYear() + " "} 
          <a
            href="https://pigijo.com"
            target="_blank"
            rel="noreferrer"
            className="footer-link fw-bolder"
          >
            PT Tourindo Guide Indonesia Tbk
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
