import { Button, Col, Row } from "react-bootstrap";
import { useFieldArray } from "react-hook-form";
import ItenaryDetailForm from "./ItenaryDetailForm";

export default function ItenaryForm({ control, register, error }) {
  const { fields, remove, insert } = useFieldArray({
    control,
    name: "itinerary",
  });

  return (
    <div>
      {fields.map((item, index) => (
        <Row key={item.id} className="mb-3">
          <Col xs="3" md="3" lg="3" xl="3">
            <div className="mb-3">
              <label htmlFor={"itineraryDay" + index} className="form-label">
                Day
              </label>
              <input
                type="number"
                id={"itineraryDay" + index}
                className="form-control"
                {...register(`itinerary[${index}].itineraryDay`)}
                defaultValue={`${item.itineraryDay}`}
              />
            </div>
          </Col>
          <ItenaryDetailForm index={index} {...{ control, register, error }} />

          {index !== 0 && (
            <div className="mb-3">
              <Button variant="danger" onClick={() => remove(index)}>
                <i className="bx bx-minus-circle"></i> Day
              </Button>
            </div>
          )}
          <hr></hr>
        </Row>
      ))}
      <Button
        variant="success"
        onClick={() =>
          insert(fields.length, {
            itineraryDay: "",
            itineraryDetail: [
              {
                itineraryTime: "",
                itineraryDescription: "",
              },
            ],
          })
        }
      >
        <i className="bx bx-plus-circle"></i> Day
      </Button>
    </div>
  );
}
