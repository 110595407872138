import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../Components/BreadcrumbComponent";
import AuthContext from "../../Services/Auth";

function Header(params) {
  let navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  return (
    <nav
      className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
      id="layout-navbar"
    >
      <div
        className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none"
        onClick={() => params.setToogle(true)}
      >
        <div className="nav-item nav-link px-0 me-xl-4">
          <i className="bx bx-menu bx-sm"></i>
        </div>
      </div>

      <div
        className="navbar-nav-right d-flex align-items-center"
        id="navbar-collapse"
      >
        <div className="navbar-nav align-items-center">
          <div className="nav-item d-flex align-items-center">
            <Breadcrumbs />
          </div>
        </div>
        <ul className="navbar-nav flex-row align-items-center ms-auto">
          <li className="nav-item lh-1 me-3" role="button">
            <i className="menu-icon tf-icons bx bxs-bell"></i>
          </li>

          <li className="nav-item navbar-dropdown dropdown-user dropdown">
            <div
              className="nav-link dropdown-toggle hide-arrow"
              data-bs-toggle="dropdown"
            >
              <div className="avatar avatar-online">
                <img
                  src="/assets/img/avatars/1.png"
                  alt=""
                  className="w-px-40 h-auto rounded-circle"
                />
              </div>
            </div>
            <ul className="dropdown-menu dropdown-menu-end">
              <li>
                <div className="dropdown-item">
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <div className="avatar avatar-online">
                        <img
                          src="/assets/img/avatars/1.png"
                          alt=""
                          className="w-px-40 h-auto rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <span className="fw-semibold d-block">
                        {(localStorage.getItem("role") === "admin" || localStorage.getItem("role") === "super admin")?localStorage.getItem("fullName"):localStorage.getItem("firstName")+" "+localStorage.getItem("lastName")}
                      </span>
                      <small className="text-muted">
                        {localStorage.getItem("role")}
                      </small>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="dropdown-divider"></div>
              </li>
              <li>
                <Link className="dropdown-item" to="/profile">
                  <i className="bx bx-user me-2"></i>
                  <span className="align-middle">My Profile</span>
                </Link>
              </li>
              <li>
                <div className="dropdown-divider"></div>
              </li>
              <li>
                <div
                  className="dropdown-item"
                  onClick={() => {
                    if (localStorage.getItem("role") === "admin" || localStorage.getItem("role") === "super admin") {
                      navigate("/login");
                    } else {
                      navigate("/login-partner");
                    }
                    logout();
                  }}
                >
                  <i className="bx bx-power-off me-2"></i>
                  <span className="align-middle">Log Out</span>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Header;
