import MaterialReactTable from "material-react-table";
import { useEffect, useState } from "react";
import { Button, Modal, Stack } from "react-bootstrap";
import { Beenhere, Visibility, Cancel } from "@mui/icons-material";

function Table(params) {
  const [modal, setModal] = useState({
    show: false,
    id: "",
    type: "",
    data: {},
  });
  const handleClose = () => {
    setModal({ show: false, id: "", type: "", data: {} });
    params.reset({
      salutation: "",
      firstName: "",
      lastName: "",
      email: "",
      countryCode: "",
      phone: "",
      isApproved: "",
      role: "",
      password: "",
      retypePassword: "",
    });
  };
  const handleShow = (id, type, data) =>
    setModal({ show: true, id: id, type: type, data: data });

  const approvePartner = async () => {
    const bodyRequest = {
      _id: modal?.id,
      isApproved: true,
    };
    let response = await params.addAction("user/approve-partner", bodyRequest);
    if (!response?.err) {
      params.getData();
      handleClose();
    }
  };

  const cancelPartner = async () => {
    const bodyRequest = {
      _id: modal?.id,
      isApproved: false,
    };
    let response = await params.addAction("user/approve-partner", bodyRequest);
    if (!response?.err) {
      params.getData();
      handleClose();
    }
  };

  useEffect(() => {
    if (modal.type === "detail" && modal.data) {
      const oldData = modal.data;
      params.getDataCity(oldData?.province);
      params.reset(oldData);
    }
  }, [modal.type]);

  return (
    <>
      <MaterialReactTable
        muiTablePaperProps={{
          sx: {
            boxShadow: "none",
            borderRadius: "1rem",
          },
        }}
        muiTopToolbarProps={{
          sx: {
            borderRadius: "1rem",
          },
        }}
        muiBottomToolbarProps={{
          sx: {
            borderRadius: "1rem",
          },
        }}
        enableRowActions
        positionActionsColumn={"last"}
        renderRowActions={(row, index) => (
          <Stack direction="horizontal" gap={2}>
            <Button
              size="sm"
              variant="primary"
              className="py-1 px-2"
              onClick={() => {
                handleShow(row?.row?.id, "detail", row?.row?.original);
              }}
            >
              <Visibility sx={{ fontSize: 14 }} />
            </Button>
            {row?.row?.original?.isApproved ? (
              <Button
                size="sm"
                variant="danger"
                className="py-1 px-2"
                onClick={() => handleShow(row?.row?.id, "cancel")}
              >
                <Cancel sx={{ fontSize: 14 }} /> Cancel
              </Button>
            ) : (
              <Button
                size="sm"
                variant="success"
                className="py-1 px-2"
                onClick={() => handleShow(row?.row?.id, "approve")}
              >
                <Beenhere sx={{ fontSize: 14 }} /> Approve
              </Button>
            )}
          </Stack>
        )}
        state={{
          isLoading: params.loading,
        }}
        getRowId={(originalRow) => originalRow._id}
        columns={params.columns}
        data={params.data}
      />
      <Modal
        show={modal.show && modal.type === "approve"}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Approve Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="h5">
          Are you sure you want to approve this travel assistance?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Back
          </Button>
          <Button variant="primary" onClick={approvePartner}>
            Approve
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modal.show && modal.type === "cancel"}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Cancel Approve Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="h5">
          Are you sure you want to cancel approve this travel assistance?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Back
          </Button>
          <Button variant="primary" onClick={cancelPartner}>
            Cancel Approve
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modal.show && modal.type === "detail"}
        onHide={handleClose}
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Detail Travel Assistance
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="h5">{params.bodyForm()}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Back
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Table;
