import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useContext, useEffect, useMemo, useState } from "react";
import { Badge, Image } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Table from "./Table";
import Layout from "../../Layout";
import AdminContext from "../../Services/Admin";
import moment from "moment";

const schema = yup
  .object({
    name: yup.string().required(),
  })
  .required();

function PartnersPage(params) {
  const { getAllMaster, loading, addMaster, addMasterForm } =
    useContext(AdminContext);
  const [data, setData] = useState([]);
  const [dataCategory, setDataCategory] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      limit: 9999,
    },
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const getParams = (params) => ({
    search: params.pagination?.search,
    page: params.pagination?.current,
    limit: params.pagination?.limit,
    sort: params.sorter?.field,
    direction: params.sorter?.order === "descend" ? "DESC" : "ASC",
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Partner Name",
      },
      {
        accessorKey: "category",
        header: "Category Name",
      },
      {
        accessorKey: "picture",
        header: "Picture",
        Cell: ({ cell }) => (
          <Image
            src={`${process.env.REACT_APP_FILE_URL}/${cell.getValue()}`}
            className="img-table"
          />
        ),
      },
      {
        accessorKey: "updatedAt",
        header: "Last Updated",
        Cell: ({ cell }) => moment(cell.getValue()).locale("id").format("LL"),
      },
      {
        accessorKey: "isActive",
        header: "Status",
        Cell: ({ cell }) => {
          let status = "Non Active";
          if (cell.getValue() === true) {
            status = "Active";
          }
          return (
            <Badge bg={status === "Active" ? "success" : "danger"}>
              {status}
            </Badge>
          );
        },
      },
    ],
    []
  );

  const bodyForm = () => {
    return (
      <>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Partner Name
          </label>
          <input
            type="text"
            id="name"
            className="form-control"
            {...register("name")}
            placeholder="Enter your partner name"
          />
          <small className="text-danger">{errors.name?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="category" className="form-label">
            Partner Category
          </label>
          <select
            id="countryCode"
            className="form-select"
            {...register("category")}
          >
            <option value="">- Select Partner Category -</option>
            {dataCategory &&
              dataCategory.map((item, index) => (
                <option value={item.name} key={index}>
                  {item.name}
                </option>
              ))}
          </select>
          <small className="text-danger">{errors.name?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="picture" className="form-label">
            Picture
          </label>
          <input
            type="file"
            id="picture"
            className="form-control"
            {...register("picture")}
            accept="image/*"
          />
          <small className="text-danger">{errors.name?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="status" className="form-label">
            Status
          </label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="status"
              value="true"
              style={{ minWidth: "50px" }}
              {...register("isActive")}
            />
            <label className="form-check-label small ps-2" htmlFor="status">
              Active
            </label>
          </div>
          <small className="text-danger">{errors.status?.message}</small>
        </div>
      </>
    );
  };

  const getData = async () => {
    const newData = await getAllMaster("partner", getParams(tableParams));
    if (newData) {
      setData(newData.data);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: newData?.data?.length,
        },
      });
    }
  };

  const getDataCategory = async () => {
    const newData = await getAllMaster("category", {
      type: "partner",
      isDeleted: false,
    });
    if (newData) {
      setDataCategory(newData.data);
    }
  };

  useEffect(() => {
    getData();
  }, [
    tableParams.sorter,
    tableParams.pagination.search,
    tableParams.pagination.limit,
    tableParams.pagination.page,
  ]);

  useEffect(() => {
    getDataCategory();
  }, []);

  return (
    <Layout>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="card">
          <Table
            columns={columns}
            data={data}
            getData={getData}
            addAction={addMaster}
            addActionForm={addMasterForm}
            bodyForm={bodyForm}
            handleSubmit={handleSubmit}
            reset={reset}
            loading={loading}
          />
        </div>
      </div>
    </Layout>
  );
}

export default PartnersPage;
