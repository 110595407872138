import { Button, Col, Row } from "react-bootstrap";
import { Controller, useFieldArray } from "react-hook-form";
import Select from "react-select";

export default function CityForm({
  control,
  error,
  dataCity,
  setCityName,
}) {
  const { fields, remove, insert } = useFieldArray({
    control,
    name: "city",
  });

  return (
    <div>
      {fields.map((item, index) => (
        <Row key={item.id} className="align-items-end">
          <Col xs="8" md="8" lg="8" xl="8">
            <div className="mb-3">
              <label htmlFor={"cityId" + index} className="form-label">
                City {index+1}
              </label>
              <Controller
                control={control}
                name={`city[${index}].cityId`}
                id={"cityId" + index}
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    ref={ref}
                    value={dataCity.find((c) => c.value === value)}
                    onChange={(val) => {
                      setCityName((items) => [
                        ...items.slice(0, index),
                        {
                          ...items[index],
                          name: val.label,
                        },
                        ...items.slice(index + 1),
                      ]);
                      onChange(val.value);
                    }}
                    options={dataCity}
                    className="form-control p-0 border-0"
                  />
                )}
              />
              <small className="text-danger">
                {error?.city?.cityId?.message}
              </small>
            </div>
          </Col>

          <Col xs="2" md="2" lg="2" xl="2">
            {index !== 0 && (
              <div className="mb-3">
                <Button variant="danger" onClick={() => remove(index)}>
                  <i className="bx bx-minus-circle"></i>
                </Button>
              </div>
            )}
          </Col>
        </Row>
      ))}
      <Button
        variant="success"
        onClick={() =>
          insert(fields.length, {
            provinceId: "",
            cityId: "",
          })
        }
      >
        <i className="bx bx-plus-circle"></i> Add City
      </Button>
    </div>
  );
}
