import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useContext, useEffect, useMemo, useState } from "react";
import { Badge, Image } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Table from "./Table";
import Layout from "../../Layout";
import AdminContext from "../../Services/Admin";
import moment from "moment";
import { toast } from "react-toastify";

const schema = yup
  .object({
    name: yup.string().required(),
  })
  .required();

function BannerPage(params) {
  const { loading, getAllMaster, addMaster, addMasterForm } =
    useContext(AdminContext);
  const [data, setData] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      limit: 9999,
    },
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const getParams = (params) => ({
    search: params.pagination?.search,
    page: params.pagination?.current,
    limit: params.pagination?.limit,
    sort: params.sorter?.field,
    direction: params.sorter?.order === "descend" ? "DESC" : "ASC",
  });

  const updateStatusAction = async (values, id) => {
    const response = await getAllMaster(
      "banner/update-status/" +
        id +
        `?isActive=${values?.target?.checked}`
    );
    if (!response?.err) {
      getData();
      toast.success("Update Status Success");
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Banner Name",
      },
      {
        accessorKey: "position",
        header: "Position",
      },
      {
        accessorKey: "picture",
        header: "Picture",
        Cell: ({ cell }) => (
          <Image
            src={`${process.env.REACT_APP_FILE_URL}/${cell.getValue()}`}
            className="img-table"
          />
        ),
      },
      {
        accessorKey: "updatedAt",
        header: "Last Updated",
        Cell: ({ cell }) => moment(cell.getValue()).locale("id").format("LL"),
      },
      {
        accessorKey: "isActive",
        header: "Status",
        Cell: ({ cell, row }) => (
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="status"
              value="true"
              style={{ minWidth: "50px" }}
              checked={cell.getValue()}
              onChange={(item) => updateStatusAction(item, row?.original?._id)}
            />
            <label className="form-check-label small ps-2" htmlFor="status">
              {cell.getValue()?"Active":"Non Active"}
            </label>
          </div>
        ),
      },
    ],
    []
  );

  const bodyForm = () => {
    return (
      <>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Banner Name
          </label>
          <input
            type="text"
            id="name"
            className="form-control"
            {...register("name")}
            placeholder="Enter your banner name"
          />
          <small className="text-danger">{errors.name?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="position" className="form-label">
            Position
          </label>
          <input
            type="number"
            id="position"
            className="form-control"
            {...register("position")}
            placeholder="Enter your banner position"
          />
          <small className="text-danger">{errors.position?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="picture" className="form-label">
            Picture
          </label>
          <input
            type="file"
            id="picture"
            className="form-control"
            {...register("picture")}
            accept="image/*"
          />
          <small className="text-danger">{errors.name?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="metaTitle" className="form-label">
            Meta Title
          </label>
          <input
            type="text"
            id="metaTitle"
            className="form-control"
            {...register("metaTitle")}
            placeholder="Enter your banner metaTitle"
          />
          <small className="text-danger">{errors.metaTitle?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="urlAction" className="form-label">
            URL Action
          </label>
          <input
            type="text"
            id="urlAction"
            className="form-control"
            {...register("urlAction")}
            placeholder="Enter your banner url action"
          />
          <small className="text-danger">{errors.urlAction?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="metaDescription" className="form-label">
            Meta Description
          </label>
          <input
            type="text"
            id="metaDescription"
            className="form-control"
            {...register("metaDescription")}
            placeholder="Enter your banner meta description"
          />
          <small className="text-danger">
            {errors.metaDescription?.message}
          </small>
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Description
          </label>
          <input
            type="text"
            id="description"
            className="form-control"
            {...register("description")}
            placeholder="Enter your banner description"
          />
          <small className="text-danger">{errors.description?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="status" className="form-label">
            Status
          </label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="status"
              value="true"
              style={{ minWidth: "50px" }}
              {...register("isActive")}
            />
            <label className="form-check-label small ps-2" htmlFor="status">
              Active
            </label>
          </div>
          <small className="text-danger">{errors.status?.message}</small>
        </div>
      </>
    );
  };

  const getData = async () => {
    const newData = await getAllMaster("banner", getParams(tableParams));
    if (newData) {
      setData(newData.data);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: newData?.data?.length,
        },
      });
    }
  };

  useEffect(() => {
    getData();
  }, [
    tableParams.sorter,
    tableParams.pagination.search,
    tableParams.pagination.limit,
    tableParams.pagination.page,
  ]);

  return (
    <Layout>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="card">
          <Table
            columns={columns}
            data={data}
            getData={getData}
            getAllAction={getAllMaster}
            addAction={addMaster}
            addActionForm={addMasterForm}
            bodyForm={bodyForm}
            handleSubmit={handleSubmit}
            reset={reset}
            loading={loading}
          />
        </div>
      </div>
    </Layout>
  );
}

export default BannerPage;
