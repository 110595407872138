import { useContext, useState } from "react";
import { Image } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import ApiWithAuth from "../../Config/Api/ApiWithAuth";
import AuthContext from "../../Services/Auth";

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();

function LoginPage({type}) {
  const { loading, login } = useContext(AuthContext);
  let navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (values) => {
    let response = await login(type, values.email, values.password);
    if (response) {
      setTimeout(() => {
        ApiWithAuth.setToken(response?.accessToken?.token);
        console.log(type)
        if (type === "admin") {
          navigate("/settings-user/admin");
        } else if (type === "partner") {
          navigate("/local-experience/products");
        }
      }, 500);
    }
  };

  return (
    <div className="container-xxl">
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner">
          <div className="card">
            <div className="card-body">
              <div className="app-brand justify-content-center">
                <a href="index.html" className="app-brand-link">
                  <span className="app-brand-logo demo">
                    <Image src="assets/img/pigijo-logo.svg" />
                  </span>
                </a>
              </div>
              <h4 className="mb-2">Welcome to Pigijo Admin Panel!</h4>
              <p className="mb-4">
                Please sign-in to your account and start the adventure
              </p>

              <form
                id="formAuthentication"
                className="mb-3"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    {...register("email")}
                    placeholder="Enter your email"
                    autoFocus
                  />
                  <small className="text-danger">{errors.email?.message}</small>
                </div>
                <div className="mb-3 form-password-toggle">
                  <div className="d-flex justify-content-between">
                    <label className="form-label" htmlFor="password">
                      Password
                    </label>
                    <a href={process.env.REACT_APP_FRONT_URL+"/send-reset-password"}>
                      <small>Forgot Password?</small>
                    </a>
                  </div>
                  <div className="input-group input-group-merge">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      className="form-control"
                      {...register("password")}
                      placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                      aria-describedby="password"
                    />
                    <span
                      className="input-group-text cursor-pointer"
                      onClick={() =>
                        setShowPassword(showPassword ? false : true)
                      }
                    >
                      <i
                        className={`bx ${showPassword ? "bx-show" : "bx-hide"}`}
                      ></i>
                    </span>
                  </div>
                  <small className="text-danger">
                    {errors.password?.message}
                  </small>
                </div>
                <div className="mb-3">
                  <button
                    className="btn btn-primary d-grid w-100"
                    type="submit"
                  >
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
