import { Button, Col, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { Controller, useFieldArray } from "react-hook-form";

export default function SpecificForm({ control, register, error }) {
  const { fields, remove, insert } = useFieldArray({
    control,
    name: "specific",
  });

  return (
    <div>
      {fields.map((item, index) => (
        <Row key={item.id} className="align-items-end">
          <Col xs="7" md="7" lg="7" xl="7">
            <div className="mb-3">
              <label htmlFor={"date" + index} className="form-label">
                Date
              </label>
              <Controller
                control={control}
                name={`specific[${index}].date`}
                render={({ field }) => (
                  <ReactDatePicker
                    placeholderText="Select date"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date()}
                    className="form-control"
                  />
                )}
              />
            </div>
          </Col>

          <Col xs="2" md="2" lg="2" xl="2">
            {index !== 0 && (
              <div className="mb-3">
                <Button variant="danger" onClick={() => remove(index)}>
                  <i className="bx bx-minus-circle"></i>
                </Button>
              </div>
            )}
          </Col>
        </Row>
      ))}
      <Button
        variant="success"
        onClick={() =>
          insert(fields.length, {
            date: "",
            specificTotal: "",
          })
        }
      >
        <i className="bx bx-plus-circle"></i> Schedule
      </Button>
    </div>
  );
}
