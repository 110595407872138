import { useState } from "react";
import Footer from "./Component/Footer";
import Header from "./Component/Header";
import Sidebar from "./Component/Sidebar";

function Layout(params) {
  const [toogle, setToogle] = useState(false);
  
  return (
    <div className={`layout-wrapper layout-content-navbar ${toogle?"layout-menu-expanded":"layout-menu-fixed"}`}>
      <div className="layout-container">
        <Sidebar toogle={toogle} setToogle={setToogle} />

        <div className="layout-page">
          <Header setToogle={setToogle} />

          <div className="content-wrapper">
            {params.children}

            <Footer />

            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>

      <div className="layout-overlay layout-menu-toggle"></div>
    </div>
  );
}

export default Layout;
