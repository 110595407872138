import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useContext, useEffect, useMemo, useState } from "react";
import { Badge, Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Table from "./Table";
import Layout from "../../Layout";
import AdminContext from "../../Services/Admin";
import Select from "react-select";

function PartnersUserPage(params) {
  const {
    getAllMaster,
    loading,
    getMaster,
    addMaster,
    updateMaster,
    deleteMaster,
  } = useContext(AdminContext);
  const [data, setData] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      page: 1,
      limit: 9999,
    },
  });
  const [dataProvince, setDataProvince] = useState([]);
  const [dataCity, setDataCity] = useState([]);
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  const getParams = (params) => ({
    search: params.pagination?.search,
    page: params.pagination?.page,
    limit: params.pagination?.limit,
    sort: params.sorter?.field,
    direction: params.sorter?.order === "descend" ? "DESC" : "ASC",
    type: "partner",
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "fullName",
        header: "Full Name",
        Cell: ({ cell }) =>
          cell?.row?.original?.firstName + " " + cell?.row?.original?.lastName,
      },
      {
        accessorKey: "contact.email",
        header: "Email",
      },
      {
        accessorKey: "contact.phone",
        header: "Phone Number",
      },
      {
        accessorKey: "partner.type",
        header: "Partner Type",
        Cell: ({ cell }) => cell.getValue().toUpperCase(),
      },
      {
        accessorKey: "isApproved",
        header: "Status",
        Cell: ({ cell }) => {
          let status = "Non Active";
          if (cell.getValue() === true) {
            status = "Active";
          }
          return (
            <Badge bg={status === "Active" ? "success" : "danger"}>
              {status}
            </Badge>
          );
        },
      },
    ],
    []
  );

  const bodyForm = () => {
    return (
      <>
        <Row>
          <Col lg="2" xl="2">
            <div className="mb-3">
              <label htmlFor="type" className="form-label">
                Type
              </label>
              <input
                type="text"
                id="type"
                disabled
                className="form-control"
                {...register("partner.type")}
                autoFocus
              />
            </div>
          </Col>
          <Col lg="5" xl="5">
            <div className="mb-3">
              <label htmlFor="firstName" className="form-label">
                First Name
              </label>
              <input
                type="text"
                id="firstName"
                disabled
                className="form-control"
                {...register("firstName")}
                placeholder="Enter your first name"
                autoFocus
              />
              <small className="text-danger">{errors.firstName?.message}</small>
            </div>
          </Col>
          <Col lg="5" xl="5">
            <div className="mb-3">
              <label htmlFor="lastName" className="form-label">
                Last Name
              </label>
              <input
                type="text"
                id="lastName"
                disabled
                className="form-control"
                {...register("lastName")}
                placeholder="Enter your last name"
              />
              <small className="text-danger">{errors.lastName?.message}</small>
            </div>
          </Col>
          <Col lg="2" xl="2">
            <div className="mb-3">
              <label htmlFor="countryCode" className="form-label">
                Country Code
              </label>
              <select
                id="countryCode"
                disabled
                className="form-select"
                {...register("contact.countryCode")}
              >
                <option value="">- Select Country Code -</option>
                <option value="62">+62 (Indonesia)</option>
              </select>
              <small className="text-danger">
                {errors.contact?.countryCode?.message}
              </small>
            </div>
          </Col>
          <Col lg="5" xl="5">
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">
                Phone Number
              </label>
              <input
                type="text"
                disabled
                id="phone"
                className="form-control"
                {...register("contact.phone")}
                placeholder="Enter your phone number"
              />
              <small className="text-danger">
                {errors.contact?.phone?.message}
              </small>
            </div>
          </Col>
          <Col lg="5" xl="5">
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="text"
                disabled
                id="email"
                className="form-control"
                {...register("contact.email")}
                placeholder="Enter your email"
              />
              <small className="text-danger">
                {errors.contact?.email?.message}
              </small>
            </div>
          </Col>
          <Col lg="12" xl="12">
            <div className="mb-3">
              <label htmlFor="address" className="form-label">
                Address
              </label>
              <textarea
                type="text"
                disabled
                id="address"
                className="form-control"
                {...register("address")}
                placeholder="Enter address"
              />
              <small className="text-danger">{errors.address?.message}</small>
            </div>
          </Col>
          <Col lg="6" xl="6">
            <div className="mb-3">
              <label htmlFor="village" className="form-label">
                Village
              </label>
              <input
                type="text"
                disabled
                id="village"
                className="form-control"
                {...register("village")}
                placeholder="Enter village"
              />
              <small className="text-danger">{errors.village?.message}</small>
            </div>
          </Col>
          <Col lg="6" xl="6">
            <div className="mb-3">
              <label htmlFor="districts" className="form-label">
                Districts
              </label>
              <input
                type="text"
                disabled
                id="districts"
                className="form-control"
                {...register("districts")}
                placeholder="Enter districts"
              />
              <small className="text-danger">{errors.districts?.message}</small>
            </div>
          </Col>
          <Col lg="6" xl="6">
            <div className="mb-3">
              <label htmlFor="city" className="form-label">
                City
              </label>
              <Controller
                control={control}
                name="city"
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    ref={ref}
                    isDisabled={true}
                    value={dataCity.find((c) => c.value === value)}
                    onChange={(val) => {
                      onChange(val.value);
                    }}
                    options={dataCity}
                    className="form-control p-0 border-0"
                  />
                )}
              />
              <small className="text-danger">{errors.city?.message}</small>
            </div>
          </Col>
          <Col lg="6" xl="6">
            <div className="mb-3">
              <label htmlFor="province" className="form-label">
                Province
              </label>
              <Controller
                control={control}
                name="province"
                render={({ field: { onChange, value, ref } }) => (
                  <Select
                    ref={ref}
                    isDisabled={true}
                    onChange={onChange}
                    options={dataProvince}
                    value={dataProvince.find((c) => c.value === value)}
                    className="form-control p-0 border-0"
                  />
                )}
              />
              <small className="text-danger">{errors.province?.message}</small>
            </div>
          </Col>
          <hr></hr>
          <Col lg="4" xl="4">
            <div className="mb-3">
              <label htmlFor="bankName" className="form-label">
                Bank Name
              </label>
              <input
                type="text"
                disabled
                id="bankName"
                className="form-control"
                {...register("partner.accountData.bankName")}
              />
              <small className="text-danger">
                {errors?.partner?.accountData?.bankName?.message}
              </small>
            </div>
          </Col>
          <Col lg="4" xl="4">
            <div className="mb-3">
              <label htmlFor="bankBranch" className="form-label">
                Bank Branch
              </label>
              <input
                type="text"
                disabled
                id="bankBranch"
                className="form-control"
                {...register("partner.accountData.bankBranch")}
              />
              <small className="text-danger">
                {errors?.partner?.accountData?.bankBranch?.message}
              </small>
            </div>
          </Col>
          <Col lg="4" xl="4">
            <div className="mb-3">
              <label htmlFor="cityBankBranch" className="form-label">
                City Bank Branch
              </label>
              <input
                type="text"
                disabled
                id="cityBankBranch"
                className="form-control"
                {...register("partner.accountData.cityBankBranch")}
              />
              <small className="text-danger">
                {errors?.partner?.accountData?.cityBankBranch?.message}
              </small>
            </div>
          </Col>
          <Col lg="6" xl="6">
            <div className="mb-3">
              <label htmlFor="beneficiaryAccountName" className="form-label">
                Beneficiary Account Name
              </label>
              <input
                type="text"
                disabled
                id="beneficiaryAccountName"
                className="form-control"
                {...register("partner.accountData.beneficiaryAccountName")}
              />
              <small className="text-danger">
                {errors?.partner?.accountData?.beneficiaryAccountName?.message}
              </small>
            </div>
          </Col>
          <Col lg="6" xl="6">
            <div className="mb-3">
              <label htmlFor="accountNumber" className="form-label">
                Account Number
              </label>
              <input
                type="text"
                disabled
                id="accountNumber"
                className="form-control"
                {...register("partner.accountData.accountNumber")}
              />
              <small className="text-danger">
                {errors?.partner?.accountData?.accountNumber?.message}
              </small>
            </div>
          </Col>
          <hr></hr>
          <Col lg="6" xl="6">
            <div className="mb-3">
              <label htmlFor="contactemail" className="form-label">
                Contact Email
              </label>
              <input
                type="text"
                disabled
                id="contactemail"
                className="form-control"
                {...register("partner.contact.email")}
              />
              <small className="text-danger">
                {errors?.partner?.contact?.email?.message}
              </small>
            </div>
          </Col>
          <Col lg="6" xl="6">
            <div className="mb-3">
              <label htmlFor="contactphoneNumber" className="form-label">
                Contact Phone Number
              </label>
              <input
                type="text"
                disabled
                id="phoneNumber"
                className="form-control"
                {...register("partner.contact.phoneNumber")}
              />
              <small className="text-danger">
                {errors?.partner?.contact?.phoneNumber?.message}
              </small>
            </div>
          </Col>
          <Col lg="6" xl="6">
            <div className="mb-3">
              <label htmlFor="contactsocialMedia" className="form-label">
                Contact Social Media
              </label>
              <input
                type="text"
                disabled
                id="contactsocialMedia"
                className="form-control"
                {...register("partner.contact.socialMedia")}
              />
              <small className="text-danger">
                {errors?.partner?.contact?.socialMedia?.message}
              </small>
            </div>
          </Col>
          <Col lg="6" xl="6">
            <div className="mb-3">
              <label
                htmlFor="taxtpayerRegistrationNumber"
                className="form-label"
              >
                Tax Payer Registration Number
              </label>
              <input
                type="text"
                disabled
                id="taxtpayerRegistrationNumber"
                className="form-control"
                {...register("partner.contact.taxtpayerRegistrationNumber")}
              />
              <small className="text-danger">
                {errors?.partner?.contact?.taxtpayerRegistrationNumber?.message}
              </small>
            </div>
          </Col>
          <hr></hr>
          <Col lg="6" xl="6">
            <div className="mb-3">
              <label htmlFor="csemail" className="form-label">
                CS Email
              </label>
              <input
                type="text"
                disabled
                id="csemail"
                className="form-control"
                {...register("partner.customerService.email")}
              />
              <small className="text-danger">
                {errors?.partner?.customerService?.email?.message}
              </small>
            </div>
          </Col>
          <Col lg="6" xl="6">
            <div className="mb-3">
              <label htmlFor="csphoneNumber" className="form-label">
                CS Phone Number
              </label>
              <input
                type="text"
                disabled
                id="csphoneNumber"
                className="form-control"
                {...register("partner.customerService.phoneNumber")}
              />
              <small className="text-danger">
                {errors?.partner?.customerService?.phoneNumber?.message}
              </small>
            </div>
          </Col>
          <Col lg="6" xl="6">
            <div className="mb-3">
              <label htmlFor="cshandphoneNumber" className="form-label">
                CS Handphone Number
              </label>
              <input
                type="text"
                disabled
                id="cshandphoneNumber"
                className="form-control"
                {...register("partner.customerService.handphoneNumber")}
              />
              <small className="text-danger">
                {errors?.partner?.customerService?.handphoneNumber?.message}
              </small>
            </div>
          </Col>
          <Col lg="6" xl="6">
            <div className="mb-3">
              <label htmlFor="csemergencyCall" className="form-label">
                CS Emergency Call
              </label>
              <input
                type="text"
                disabled
                id="csemergencyCall"
                className="form-control"
                {...register("partner.customerService.emergencyCall")}
              />
              <small className="text-danger">
                {errors?.partner?.customerService?.emergencyCall?.message}
              </small>
            </div>
          </Col>
          <Col lg="12" xl="12">
            <div className="mb-3">
              <label htmlFor="insurance" className="form-label">
                Customer Service Insurance
              </label>
              <input
                type="text"
                disabled
                id="insurance"
                className="form-control"
                {...register("partner.customerService.insurance")}
              />
              <small className="text-danger">
                {errors?.partner?.customerService?.insurance?.message}
              </small>
            </div>
          </Col>
          <hr></hr>
          <Col lg="9" xl="9">
            <div className="mb-3">
              <label htmlFor="cancelationPolicy" className="form-label">
                Cancelation Policy
              </label>
              <input
                type="text"
                disabled
                id="cancelationPolicy"
                className="form-control"
                {...register("partner.orderingTerms.cancelationPolicy")}
              />
              <small className="text-danger">
                {errors?.partner?.orderingTerms?.cancelationPolicy?.message}
              </small>
            </div>
          </Col>
          <Col lg="3" xl="3">
            <div className="mb-3">
              <label htmlFor="minimumOrderDay" className="form-label">
                Minimum Order Day
              </label>
              <input
                type="text"
                disabled
                id="minimumOrderDay"
                className="form-control"
                {...register("partner.orderingTerms.minimumOrderDay")}
              />
              <small className="text-danger">
                {errors?.partner?.orderingTerms?.minimumOrderDay?.message}
              </small>
            </div>
          </Col>
        </Row>
      </>
    );
  };

  const getData = async () => {
    const newData = await getAllMaster("user", getParams(tableParams));
    if (newData) {
      setData(newData.data);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: newData?.data?.length,
        },
      });
    }
  };

  const getDataProvince = async () => {
    const newData = await getAllMaster(`address/province`);
    if (newData) {
      let dataProvinces = [
        {
          value: "",
          label: "- Select Province -",
        },
      ];
      dataProvinces.push(
        ...newData?.data?.map((item) => ({
          value: item.province_id,
          label: item.province,
        }))
      );

      setDataProvince(dataProvinces);
    }
  };

  const getDataCity = async (province_id) => {
    const newData = await getAllMaster(
      `address/city?provinceId=${province_id}`
    );
    if (newData) {
      let dataCitys = [
        {
          value: "",
          label: "- Select City -",
        },
      ];

      dataCitys.push(
        ...newData?.data?.map((item) => ({
          value: item.city_id,
          label: item.city_name,
        }))
      );

      setDataCity(dataCitys);
    }
  };

  useEffect(() => {
    getData();
  }, [
    tableParams.sorter,
    tableParams.pagination.search,
    tableParams.pagination.limit,
    tableParams.pagination.page,
  ]);

  useEffect(() => {
    getDataProvince();
  }, []);

  return (
    <Layout>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="card">
          <Table
            columns={columns}
            data={data}
            getData={getData}
            getDataCity={getDataCity}
            getAction={getMaster}
            addAction={addMaster}
            updateAction={updateMaster}
            deleteAction={deleteMaster}
            bodyForm={bodyForm}
            handleSubmit={handleSubmit}
            reset={reset}
            loading={loading}
          />
        </div>
      </div>
    </Layout>
  );
}

export default PartnersUserPage;
