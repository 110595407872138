import { useContext, useEffect, useState } from "react";
import { Badge, Card, Col, Row } from "react-bootstrap";
import Layout from "../../Layout";
import AdminContext from "../../Services/Admin";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useParams } from "react-router-dom";
import moment from "moment";

const typeStatus = (status) => {
  let color = "light";
  if (status === "pending") {
    color = "warning";
  } else if (status === "settlement") {
    color = "success";
  } else if (status === "cancel" || status === "expire" || status === "deny") {
    color = "danger";
  }

  return color;
};

function TransactionLocalExperienceDetailPage(params) {
  const { getMaster, loading } = useContext(AdminContext);
  const [data, setData] = useState({});
  const { id } = useParams();

  const getData = async () => {
    const newData = await getMaster(`order`, id);
    if (newData) {
      setData(newData.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Layout>
      <div className="container-xxl flex-grow-1 container-p-y">
        <Row>
          <Col xs="12" md="6" xl="6" lg="6" className="mb-4">
            <Card className="h-100">
              <Card.Body className="h-100">
                <Card.Title>Customer Information</Card.Title>
                <hr></hr>
                <table>
                  <tbody>
                    <tr>
                      <th style={{ minWidth: "200px" }}>Full Name</th>
                      <td>
                        : {data?.user?.firstName + " " + data?.user?.lastName}
                      </td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>: {data?.user?.email}</td>
                    </tr>
                    <tr>
                      <th>Phone Number</th>
                      <td>: {data?.user?.phone}</td>
                    </tr>
                  </tbody>
                </table>
              </Card.Body>
            </Card>
          </Col>
          <Col xs="12" md="6" xl="6" lg="6" className="mb-4">
            <Card className="h-100">
              <Card.Body className="h-100">
                <Card.Title>Payment Information</Card.Title>
                <hr></hr>
                <table>
                  <tbody>
                    <tr>
                      <th style={{ minWidth: "200px" }}>Status</th>
                      <td>
                        :{" "}
                        <Badge bg={typeStatus(data?.status)}>
                          {data?.status}
                        </Badge>
                      </td>
                    </tr>
                    <tr>
                      <th>Method</th>
                      <td>: {data?.paymentMethod?.toUpperCase()}</td>
                    </tr>
                    <tr>
                      <th>Bank</th>
                      <td>: {data?.va?.bank?.toUpperCase()}</td>
                    </tr>
                    <tr>
                      <th>VA Number</th>
                      <td>: {data?.va?.vaNumber}</td>
                    </tr>
                    <tr>
                      <th>Amount</th>
                      <td>
                        :{" "}
                        {new Intl.NumberFormat("id-ID", {
                          style: "currency",
                          currency: "IDR",
                          minimumFractionDigits: 0,
                        }).format(data?.grossAmount)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card.Body>
            </Card>
          </Col>
          <Col xs="12" md="6" xl="6" lg="6" className="mb-4">
            <Card className="h-100">
              <Card.Body className="h-100">
                <Card.Title>Order Information</Card.Title>
                <hr></hr>
                <table>
                  <tbody>
                    <tr>
                      <th style={{ minWidth: "200px" }}>Order Code</th>
                      <td>: {data?.invoiceNumber}</td>
                    </tr>
                    <tr>
                      <th>Order Amount</th>
                      <td>: {data?.items?.length>0&&data?.items[0]?.quantity}</td>
                    </tr>
                    <tr>
                      <th>Order Name</th>
                      <td>: {data?.items?.length>0&&data?.items[0]?.name}</td>
                    </tr>
                    <tr>
                      <th>Departure Date</th>
                      <td>: {moment(data?.departureDate).format("LL")}</td>
                    </tr>
                    <tr>
                      <th>Created At</th>
                      <td>: {moment(data?.createdAt).format("DD MMMM YYYY HH:mm")}</td>
                    </tr>
                  </tbody>
                </table>
              </Card.Body>
            </Card>
          </Col>
          <Col xs="12" md="6" xl="6" lg="6" className="mb-4">
            <Card className="h-100">
              <Card.Body className="h-100">
                <Card.Title>Status Histories</Card.Title>
                <hr></hr>
                <div className="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                  {data?.logStatusOrder &&
                    data?.logStatusOrder?.map((item, index) => (
                      <div
                        className="vertical-timeline-item vertical-timeline-element"
                        key={index}
                      >
                        <div>
                          <span className="vertical-timeline-element-icon">
                            <Badge className="badge-dot badge-dot-xl"> </Badge>
                          </span>
                          <div className="vertical-timeline-element-content bounce-in">
                            <h4 className="timeline-title">{item.status}</h4>
                            <p>Actor: {item.actor}</p>
                            <span className="vertical-timeline-element-date">
                              {moment(item.createdAt).format(
                                "YYYY/MM/DD HH:mm"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xs="12" md="6" xl="6" lg="6" className="mb-4">
            <Card className="h-100">
              <Card.Body className="h-100">
                <Card.Title>Customer Information</Card.Title>
                <hr></hr>
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Full Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.customers &&
                        data?.customers?.map((item, index) => (
                          <tr key={index}>
                            <td>{item.firstName + " " + item.lastName}</td>
                            <td>{item.email}</td>
                            <td>{item.phone}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xs="12" md="6" xl="6" lg="6" className="mb-4">
            <Card>
              <Card.Body className="h-100">
                <Card.Title>Meta Data</Card.Title>
                <hr></hr>
                <SyntaxHighlighter
                  className="inline-code-preview"
                  wrapLongLines
                  showLineNumbers
                  language="json"
                  style={docco}
                >
                  {JSON.stringify(data, null, 2)}
                </SyntaxHighlighter>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default TransactionLocalExperienceDetailPage;
