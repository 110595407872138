import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useContext, useEffect, useMemo, useState } from "react";
import { Badge } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Table from "./Table";
import Layout from "../../Layout";
import AdminContext from "../../Services/Admin";
import moment from "moment";
import ReactDatePicker from "react-datepicker";

const schema = yup
  .object({
    code: yup.string().required(),
    discount: yup.string().required(),
    voucherType: yup.string().required(),
    startDate: yup.string().required(),
    endDate: yup.string().required(),
  })
  .required();

function VoucherPage(params) {
  const { getAllMaster, loading, addMaster } = useContext(AdminContext);
  const [data, setData] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      limit: 9999,
    },
  });
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const getParams = (params) => ({
    search: params.pagination?.search,
    page: params.pagination?.current,
    limit: params.pagination?.limit,
    sort: params.sorter?.field,
    direction: params.sorter?.order === "descend" ? "DESC" : "ASC",
    // type: "partner",
    // isDeleted: false,
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "code",
        header: "Voucher Code",
      },
      {
        accessorKey: "discount",
        header: "Discount",
        Cell: ({ cell, row }) =>
          row.original?.voucherType === "percent"
            ? cell?.getValue() + "%"
            : new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
              }).format(cell.getValue()),
      },
      {
        accessorKey: "voucherType",
        header: "Voucher Type",
      },
      {
        accessorKey: "startDate",
        header: "Start Date",
        Cell: ({ cell }) => moment(cell.getValue()).locale("id").format("LL"),
      },
      {
        accessorKey: "endDate",
        header: "End Date",
        Cell: ({ cell }) => moment(cell.getValue()).locale("id").format("LL"),
      },
      {
        accessorKey: "isActive",
        header: "Status",
        Cell: ({ cell }) => {
          let status = "Non Active";
          if (cell.getValue() === true) {
            status = "Active";
          }
          return (
            <Badge bg={status === "Active" ? "success" : "danger"}>
              {status}
            </Badge>
          );
        },
      },
    ],
    []
  );

  const bodyForm = () => {
    return (
      <>
        <div className="mb-3">
          <label htmlFor="code" className="form-label">
            Voucher Code
          </label>
          <input
            type="text"
            id="code"
            className="form-control"
            {...register("code")}
            maxLength="8"
            placeholder="Enter your voucher code"
            autoFocus
            readOnly
          />
          <small className="text-danger">{errors.code?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="startDate" className="form-label">
            Start Date
          </label>
          <Controller
            control={control}
            name={`startDate`}
            render={({ field }) => (
              <ReactDatePicker
                placeholderText="Select start date"
                onChange={(date) => field.onChange(date)}
                selected={field.value}
                dateFormat="dd/MM/yyyy"
                selectsStart
                startDate={field.value}
                endDate={watch("endDate")}
                minDate={new Date()}
                className="form-control"
              />
            )}
          />
          <small className="text-danger">{errors.startDate?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="endDate" className="form-label">
            End Date
          </label>
          <Controller
            control={control}
            name={`endDate`}
            render={({ field }) => (
              <ReactDatePicker
                placeholderText="Select end date"
                onChange={(date) => field.onChange(date)}
                selected={field.value}
                dateFormat="dd/MM/yyyy"
                selectsEnd
                startDate={watch("startDate")}
                endDate={field.value}
                minDate={new Date()}
                className="form-control"
              />
            )}
          />
          <small className="text-danger">{errors.endDate?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="voucherType" className="form-label">
            Voucher Type
          </label>
          <select
            id="voucherType"
            className="form-select"
            {...register("voucherType")}
          >
            <option value="">- Select Voucher Type -</option>
            <option value="percent">Percent</option>
            <option value="idr">Rupiah</option>
          </select>
          <small className="text-danger">{errors.voucherType?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="discount" className="form-label">
            Discount
          </label>
          <input
            type="number"
            id="discount"
            className="form-control text-end"
            {...register("discount")}
            placeholder="Enter your discount"
          />
          <small className="text-danger">{errors.discount?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="isActive" className="form-label">
            Status
          </label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="isActive"
              value={true}
              style={{ minWidth: "50px" }}
              {...register("isActive")}
            />
            <label className="form-check-label small ps-2" htmlFor="isActive">
              Active
            </label>
          </div>
          <small className="text-danger">{errors.isActive?.message}</small>
        </div>
      </>
    );
  };

  const getData = async () => {
    const newData = await getAllMaster(
      "voucher/got-voucher",
      getParams(tableParams)
    );
    if (newData) {
      setData(newData.data);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: newData?.data?.length,
        },
      });
    }
  };

  useEffect(() => {
    getData();
  }, [
    tableParams.sorter,
    tableParams.pagination.search,
    tableParams.pagination.limit,
    tableParams.pagination.page,
  ]);

  return (
    <Layout>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="card">
          <Table
            columns={columns}
            data={data}
            getData={getData}
            addAction={addMaster}
            bodyForm={bodyForm}
            handleSubmit={handleSubmit}
            reset={reset}
            loading={loading}
          />
        </div>
      </div>
    </Layout>
  );
}

export default VoucherPage;
