import { useContext, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Layout from "../../Layout";
import AdminContext from "../../Services/Admin";
import { Controller, useForm } from "react-hook-form";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";

const schema = yup
  .object({
    salutation: yup.string().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    countryCode: yup.string().required(),
    phone: yup.string().required(),
    address: yup.string().required(),
    village: yup.string().required(),
    districts: yup.string().required(),
    city: yup.string().required(),
    province: yup.string().required(),
    postalCode: yup.string().required(),
  })
  .required();

const schema2 = yup
  .object({
    oldPassword: yup.string().min(5).required("Password is required"),
    newPassword: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    cNewPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
  })
  .required();

function ProfilePage(params) {
  const { loading, getAllMaster, getMaster, addMasterForm } =
    useContext(AdminContext);
  const [data, setData] = useState({});
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [dataProvince, setDataProvince] = useState([]);
  const [dataCity, setDataCity] = useState([]);
  const [dataDistricts, setDataDistricts] = useState([]);
  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      salutation: "",
      firstName: "",
      lastName: "",
      email: "",
      countryCode: "",
      phone: "",
      address: "",
      village: "",
      districts: "",
      city: "",
      province: "",
      postalCode: "",
      picture: "",
    },
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    formState: { errors: errors2 },
  } = useForm({
    resolver: yupResolver(schema2),
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      cNewPassword: "",
    },
  });

  const [dataTitle, setDataTitle] = useState([
    {
      value: "Mr",
      label: "Mr",
    },
    {
      value: "Ms",
      label: "Ms",
    },
    {
      value: "Mrs",
      label: "Mrs",
    },
  ]);
  const [dataCountryCode, setDataCountryCode] = useState([
    {
      value: 62,
      label: "+62",
    },
  ]);

  const getDataProvince = async () => {
    const newData = await getAllMaster(`address/province`);
    if (newData) {
      let dataProvinces = [
        {
          value: "",
          label: "- Select Province -",
        },
      ];
      dataProvinces.push(
        ...newData?.data?.map((item) => ({
          value: item.province_id,
          label: item.province,
        }))
      );

      setDataProvince(dataProvinces);
    }
  };

  const getDataCity = async (province_id) => {
    const newData = await getAllMaster(
      `address/city?provinceId=${province_id}`
    );
    if (newData) {
      let dataCitys = [
        {
          value: "",
          label: "- Select City -",
        },
      ];

      dataCitys.push(
        ...newData?.data?.map((item) => ({
          value: item.city_id,
          label: item.city_name,
        }))
      );

      setDataCity(dataCitys);
    }
  };

  const getDataDistricts = async (city_id) => {
    const newData = await getAllMaster(`address/subdistrict?cityId=${city_id}`);
    if (newData) {
      let dataDistrictss = [
        {
          value: "",
          label: "- Select Districts -",
        },
      ];

      dataDistrictss.push(
        ...newData?.data?.map((item) => ({
          value: item.subdistrict_id,
          label: item.subdistrict_name,
        }))
      );

      setDataDistricts(dataDistrictss);
    }
  };

  const getData = async () => {
    const newData = await getAllMaster("user/got-member-detail");
    if (newData) {
      let oldData = newData.data;
      setData(oldData);
      getDataCity(oldData?.province);
      getDataDistricts(oldData?.city);
      reset({
        firstName: oldData?.firstName,
        lastName: oldData?.lastName,
        address: oldData?.address,
        salutation: oldData?.salutation,
        phone: oldData?.contact?.phone,
        countryCode: oldData?.contact?.countryCode,
        email: oldData?.contact?.email,
        postalCode: oldData?.postalCode,
        province: oldData?.province,
        city: oldData?.city,
        districts: oldData?.districts,
        village: oldData?.village,
      });
      setImagePreview(
        process.env.REACT_APP_FILE_URL + "/" + oldData?.photoProfile
      );
    }
  };

  useEffect(() => {
    watch((value, { name, type }) => {
      if (name === "province" && type === "change") {
        getValues("city") && setValue("city", "");
        getValues("districts") && setValue("districts", "");
        setDataCity([
          {
            value: "",
            label: "- Select City -",
          },
        ]);
        setDataDistricts([
          {
            value: "",
            label: "- Select Districts -",
          },
        ]);
        getDataCity(value?.province);
      } else if (name === "city" && type === "change") {
        getValues("districts") && setValue("districts", "");
        setDataDistricts([
          {
            value: "",
            label: "- Select Districts -",
          },
        ]);
        getDataDistricts(value?.city);
      } else if (name === "picture" && type === "change") {
        // console.log(URL.createObjectURL(value.picture[0]))
        setImagePreview(URL.createObjectURL(value.picture[0]));
      }
    });
  }, [watch]);

  useEffect(() => {
    getData();
    getDataProvince();
  }, []);

  const updateAction = async (values) => {
    const formData = new FormData();
    formData.append("picture", values.picture[0]);
    formData.append("salutation", values.salutation);
    formData.append("firstName", values.firstName);
    formData.append("lastName", values.lastName);
    formData.append("email", values.email);
    formData.append("countryCode", values.countryCode);
    formData.append("phone", values.phone);
    formData.append("address", values.address);
    formData.append("village", values.village);
    formData.append("districts", values.districts);
    formData.append("city", values.city);
    formData.append("province", values.province);
    formData.append("postalCode", values.postalCode);

    let response = await addMasterForm(
      "user/update-member/" + localStorage.getItem("_id"),
      formData
    );
    if (!response?.err) {
      getData();
      toast.success("Update Profile Success");
    }
  };

  const updatePassword = async (values) => {
    let response = await addMasterForm((localStorage.getItem("role") === "travelAssistance"?"user":"admin")+"/change-password", values);
    if (!response?.err) {
      reset2();
      toast.success("Update Password Success");
    }
  };

  return (
    <Layout>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="row">
          <div className="col-md-12">
            {localStorage.getItem("role") === "travelAssistance" && (
              <div className="card mb-4">
                <h5 className="card-header">Profile Details</h5>
                <div className="card-body">
                  <div className="d-flex align-items-start align-items-sm-center gap-4">
                    <img
                      src={imagePreview}
                      alt="user-avatar"
                      className="d-block rounded"
                      height="100"
                      width="100"
                      style={{ objectFit: "cover", objectPosition: "center" }}
                      id="uploadedAvatar"
                    />
                    <div className="button-wrapper">
                      <label
                        htmlFor="upload"
                        className="btn btn-primary me-2 mb-4"
                        tabIndex="0"
                      >
                        <span className="d-none d-sm-block">
                          Upload new photo
                        </span>
                        <i className="bx bx-upload d-block d-sm-none"></i>
                        <input
                          {...register("picture")}
                          type="file"
                          id="upload"
                          className="account-file-input"
                          hidden
                          accept="image/png, image/jpeg"
                        />
                      </label>
                      <p className="text-muted mb-0">
                        Allowed JPG or PNG. Max size of 1MB
                      </p>
                    </div>
                  </div>
                </div>
                <hr className="my-0" />
                <div className="card-body">
                  <div className="row">
                    <div className="mb-3 col-md-2">
                      <label htmlFor="title" className="form-label">
                        Title
                      </label>
                      <Controller
                        control={control}
                        name="salutation"
                        id="title"
                        render={({ field: { onChange, value, ref } }) => (
                          <ReactSelect
                            ref={ref}
                            className="select"
                            classNamePrefix="select"
                            placeholder="Title"
                            isSearchable={false}
                            value={dataTitle.find((c) => c.value === value)}
                            onChange={(val) => onChange(val.value)}
                            options={dataTitle}
                          />
                        )}
                      />
                      <small className="text-danger">
                        {errors.salutation?.message}
                      </small>
                    </div>
                    <div className="mb-3 col-md-5">
                      <label htmlFor="firstName" className="form-label">
                        First Name
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="firstName"
                        placeholder="First Name"
                        {...register("firstName")}
                      />
                      <small className="text-danger">
                        {errors.firstName?.message}
                      </small>
                    </div>
                    <div className="mb-3 col-md-5">
                      <label htmlFor="lastName" className="form-label">
                        Last Name
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Last Name"
                        {...register("lastName")}
                        id="lastName"
                      />
                      <small className="text-danger">
                        {errors.lastName?.message}
                      </small>
                    </div>
                    <div className="mb-2 col-md-2">
                      <label htmlFor="countryCode" className="form-label">
                        Country Code
                      </label>
                      <Controller
                        control={control}
                        name="countryCode"
                        render={({ field: { onChange, value, ref } }) => (
                          <ReactSelect
                            ref={ref}
                            className="select"
                            classNamePrefix="select"
                            isSearchable={false}
                            placeholder="+xx"
                            value={dataCountryCode.find(
                              (c) => c.value === value
                            )}
                            onChange={(val) => onChange(val.value)}
                            options={dataCountryCode}
                          />
                        )}
                      />
                      <small className="text-danger">
                        {errors.countryCode?.message}
                      </small>
                    </div>
                    <div className="mb-3 col-md-5">
                      <label htmlFor="phone" className="form-label">
                        Phone Number
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Phone Number"
                        {...register("phone")}
                        id="phone"
                      />
                      <small className="text-danger">
                        {errors.phone?.message}
                      </small>
                    </div>
                    <div className="mb-3 col-md-5">
                      <label htmlFor="email" className="form-label">
                        E-mail
                      </label>
                      <input
                        className="form-control"
                        {...register("email")}
                        type="email"
                        id="email"
                        placeholder="E-mail"
                      />
                      <small className="text-danger">
                        {errors.email?.message}
                      </small>
                    </div>
                    <div className="mb-3 col-md-7">
                      <label htmlFor="address" className="form-label">
                        Address
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="address"
                        {...register("address")}
                        placeholder="Address"
                      />
                      <small className="text-danger">
                        {errors.address?.message}
                      </small>
                    </div>
                    <div className="mb-3 col-md-3">
                      <label htmlFor="village" className="form-label">
                        Village
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Village"
                        {...register("village")}
                        id="village"
                      />
                      <small className="text-danger">
                        {errors.village?.message}
                      </small>
                    </div>
                    <div className="mb-3 col-md-2">
                      <label htmlFor="postalCode" className="form-label">
                        Postal Code
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Postal Code"
                        {...register("postalCode")}
                        id="postalCode"
                      />
                      <small className="text-danger">
                        {errors.postalCode?.message}
                      </small>
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="province" className="form-label">
                        Province
                      </label>
                      <Controller
                        control={control}
                        name="province"
                        render={({ field: { onChange, value, ref } }) => (
                          <ReactSelect
                            ref={ref}
                            className="select"
                            classNamePrefix="select"
                            isSearchable={true}
                            placeholder="Province"
                            value={dataProvince.find((c) => c.value === value)}
                            onChange={(val) => onChange(val.value)}
                            options={dataProvince}
                          />
                        )}
                      />
                      <small className="text-danger">
                        {errors.province?.message}
                      </small>
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="city" className="form-label">
                        City
                      </label>
                      <Controller
                        control={control}
                        name="city"
                        render={({ field: { onChange, value, ref } }) => (
                          <ReactSelect
                            ref={ref}
                            className="select"
                            classNamePrefix="select"
                            isSearchable={true}
                            placeholder="City/Regency"
                            value={dataCity.find((c) => c.value === value)}
                            onChange={(val) => onChange(val.value)}
                            options={dataCity}
                          />
                        )}
                      />
                      <small className="text-danger">
                        {errors.city?.message}
                      </small>
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="districts" className="form-label">
                        Districts
                      </label>
                      <Controller
                        control={control}
                        name="districts"
                        render={({ field: { onChange, value, ref } }) => (
                          <ReactSelect
                            ref={ref}
                            className="select"
                            classNamePrefix="select"
                            isSearchable={true}
                            placeholder="Districts"
                            value={dataDistricts.find((c) => c.value === value)}
                            onChange={(val) => onChange(val.value)}
                            options={dataDistricts}
                          />
                        )}
                      />
                      <small className="text-danger">
                        {errors.districts?.message}
                      </small>
                    </div>
                  </div>
                  <div className="mt-2">
                    <Button
                      variant="primary"
                      className="me-2"
                      onClick={handleSubmit(updateAction)}
                      disabled={loading}
                    >
                      Save changes
                    </Button>
                  </div>
                </div>
              </div>
            )}
            <div className="card">
              <h5 className="card-header">Update Password</h5>
              <div className="card-body">
                <div className="row">
                  <div className="mb-4 col-md-4">
                    <label htmlFor="oldPassword" className="form-label">
                      Old Password
                    </label>
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Old Password"
                      {...register2("oldPassword")}
                      id="oldPassword"
                    />
                    <small className="text-danger">
                      {errors2.oldPassword?.message}
                    </small>
                  </div>
                  <div className="mb-4 col-md-4">
                    <label htmlFor="newPassword" className="form-label">
                      New Password
                    </label>
                    <input
                      className="form-control"
                      type="password"
                      placeholder="New Password"
                      {...register2("newPassword")}
                      id="newPassword"
                    />
                    <small className="text-danger">
                      {errors2.newPassword?.message}
                    </small>
                  </div>
                  <div className="mb-4 col-md-4">
                    <label htmlFor="cNewPassword" className="form-label">
                      Confirm New Password
                    </label>
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Confirm New Password"
                      {...register2("cNewPassword")}
                      id="cNewPassword"
                    />
                    <small className="text-danger">
                      {errors2.cNewPassword?.message}
                    </small>
                  </div>
                </div>

                <div className="mt-2">
                  <Button
                    variant="primary"
                    className="me-2"
                    onClick={handleSubmit2(updatePassword)}
                    disabled={loading}
                  >
                    Save changes
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ProfilePage;
