import { Button, Col } from "react-bootstrap";
import { useFieldArray } from "react-hook-form";

export default function ItenaryDetailForm({ index, control, register }) {
  const { fields, remove, insert } = useFieldArray({
    control,
    name: `itinerary[${index}].itineraryDetail`,
  });

  return (
    <Col xs="9" md="9" lg="9" xl="9">
      {fields.map((subItem, subIndex) => (
        <div key={index + "_" + subIndex}>
          <div className="mb-3">
            <label
              htmlFor={"itineraryTime" + index + "_" + subIndex}
              className="form-label"
            >
              Time
            </label>
            <input
              type="text"
              className="form-control"
              id={"itineraryTime" + index + "_" + subIndex}
              defaultValue={`${subItem.itineraryTime}`}
              {...register(`itinerary[${index}].itineraryDetail[${subIndex}].itineraryTime`)}
              placeholder="Enter itinerary time"
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor={"itineraryDescription" + index + "_" + subIndex}
              className="form-label"
            >
              Description
            </label>
            <textarea
              className="form-control"
              id={"itineraryDescription" + index + "_" + subIndex}
              {...register(`itinerary[${index}].itineraryDetail[${subIndex}].itineraryDescription`)}
              defaultValue={`${subItem.itineraryDescription}`}
              placeholder="Enter itinerary description"
            />
          </div>
          {subIndex !== 0 && (
            <Button variant="danger" onClick={() => remove(subIndex)}>
              <i className="bx bx-minus-circle"></i> Activity {subIndex}
            </Button>
          )}
          <hr></hr>
        </div>
      ))}
      <div className="mb-3">
        <Button
          variant="success"
          onClick={() =>
            insert(fields.length, {
              itineraryTime: "",
              itineraryDescription: "",
            })
          }
        >
          <i className="bx bx-plus-circle"></i> Activity
        </Button>
      </div>
    </Col>
  );
}
