import { useContext, useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Select from "react-select";
import Table from "./Table";
import Layout from "../../Layout";
import AdminContext from "../../Services/Admin";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";

const schema = yup
  .object({
    name: yup.string().required(),
  })
  .required();

const defaultValues = {
  name: "",
  position: "",
  category: "",
};

function MenuRecomendationPage(params) {
  const { loading, getMaster, getAllMaster, addMaster, addMasterForm } =
    useContext(AdminContext);
  const [data, setData] = useState([]);
  const [dataCategory, setDataCategory] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "position",
        header: "Position",
      },
      {
        accessorKey: "category",
        header: "Category",
        Cell: ({ cell }) =>
          cell?.getValue()?.length > 0 &&
          cell
            .getValue()
            .map(
              (item, index) =>
                item + (index < cell.getValue()?.length-1 ? ", " : "")
            ),
      },
      {
        accessorKey: "isActive",
        header: "Status",
        Cell: ({ cell, row }) => (
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="status"
              value="true"
              style={{ minWidth: "50px" }}
              checked={cell.getValue()}
              onChange={(item) => updateStatusAction(item, row?.original?._id)}
            />
            <label className="form-check-label small ps-2" htmlFor="status">
              {cell.getValue()?"Active":"Non Active"}
            </label>
          </div>
        ),
      },
      {
        accessorKey: "updatedAt",
        header: "Last Updated",
        Cell: ({ cell }) => moment(cell.getValue()).locale("id").format("LL"),
      },
    ],
    []
  );

  const bodyForm = () => {
    return (
      <>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Recomendation Name
          </label>
          <input
            type="text"
            id="name"
            className="form-control"
            {...register("name")}
            placeholder="Enter your recomendation name"
          />
          <small className="text-danger">{errors.name?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="position" className="form-label">
            Position
          </label>
          <input
            type="number"
            min={1}
            max={4}
            id="position"
            className="form-control"
            {...register("position")}
          />
          <small className="text-danger">{errors.position?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="category" className="form-label">
            Product Category
          </label>
          <Controller
            control={control}
            name="category"
            render={({ field: { onChange, value, ref } }) => (
              <Select
                ref={ref}
                onChange={onChange}
                options={dataCategory.map((item) => ({
                  value: item.name,
                  label: item.name,
                }))}
                value={value}
                isMulti
                className="form-control p-0 border-0"
              />
            )}
          />
          <small className="text-danger">{errors.category?.message}</small>
        </div>
      </>
    );
  };

  const updateStatusAction = async (values, id) => {
    const response = await getAllMaster(
      "menu/recomended/set-active/" +
        id +
        `?isActive=${values?.target?.checked}`
    );
    if (!response?.err) {
      getData();
      toast.success("Update Status Success");
    }
  };

  const getDataCategory = async () => {
    const newData = await getAllMaster("category", {
      type: "product",
      isDeleted: false,
    });
    if (newData) {
      setDataCategory(newData.data);
    }
  };

  const getData = async () => {
    const newData = await getAllMaster("menu/recomendeds/adp");
    if (newData) {
      setData(newData.data);
    }
  };

  useEffect(() => {
    getData();
    getDataCategory();
  }, []);

  return (
    <Layout>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="card">
          <Table
            columns={columns}
            data={data}
            getData={getData}
            getAllAction={getAllMaster}
            getAction={getMaster}
            addAction={addMaster}
            addActionForm={addMasterForm}
            bodyForm={bodyForm}
            handleSubmit={handleSubmit}
            reset={reset}
            loading={loading}
          />
        </div>
      </div>
    </Layout>
  );
}

export default MenuRecomendationPage;
