import { useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useLocation } from "react-router-dom";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function Breadcrumbs(params) {
  const { pathname } = useLocation();
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  useEffect(() => {
    const newPathArray = pathname.split("/");
    if (newPathArray.hasOwnProperty(1)) {
      setActiveMenu(capitalizeFirstLetter(newPathArray[1]));
    }
    if (newPathArray.hasOwnProperty(2)) {
      setActiveSubMenu(capitalizeFirstLetter(newPathArray[2]));
    }
  }, [pathname]);

  return (
    <Breadcrumb>
      <Breadcrumb.Item active={!activeSubMenu}>{activeMenu}</Breadcrumb.Item>
      {activeSubMenu && (
        <Breadcrumb.Item active={activeSubMenu}>
          {activeSubMenu}
        </Breadcrumb.Item>
      )}
    </Breadcrumb>
  );
}

export default Breadcrumbs;
