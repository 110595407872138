import { useContext, useEffect, useMemo, useState } from "react";
import Table from "./Table";
import Layout from "../../Layout";
import AdminContext from "../../Services/Admin";
import moment from "moment";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

function MenuFooterPage(params) {
  const { loading, getMaster, getAllMaster } =
    useContext(AdminContext);
  const [data, setData] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "position",
        header: "Position",
      },
      {
        accessorKey: "isActive",
        header: "Status",
        Cell: ({ cell, row }) => (
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="status"
              value="true"
              style={{ minWidth: "50px" }}
              checked={cell.getValue()}
              onChange={(item) => updateStatusAction(item, row?.original?._id)}
            />
            <label className="form-check-label small ps-2" htmlFor="status">
              Active
            </label>
          </div>
        ),
      },
      {
        accessorKey: "updatedAt",
        header: "Last Updated",
        Cell: ({ cell }) => moment(cell.getValue()).locale("id").format("LL"),
      },
    ],
    []
  );

  const bodyForm = () => {
    return (
      <>
        <div className="mb-3">
          <label htmlFor="position" className="form-label">
            Position
          </label>
          <input
            type="number"
            min={1}
            max={4}
            id="position"
            className="form-control"
            {...register("position")}
          />
          <small className="text-danger">{errors.position?.message}</small>
        </div>
      </>
    );
  };

  const updateStatusAction = async (values, id) => {
    const response = await getAllMaster(
      "menu/footer/set-active/" + id + `?isActive=${values?.target?.checked}`
    );
    if (!response?.err) {
      getData();
      toast.success("Update Status Success");
    }
  };

  const getData = async () => {
    const newData = await getAllMaster("menu/footers/adp");
    if (newData) {
      setData(newData.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Layout>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="card">
          <Table
            columns={columns}
            data={data}
            getData={getData}
            getAllAction={getAllMaster}
            getAction={getMaster}
            bodyForm={bodyForm}
            handleSubmit={handleSubmit}
            reset={reset}
            loading={loading}
          />
        </div>
      </div>
    </Layout>
  );
}

export default MenuFooterPage;
