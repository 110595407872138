import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useContext, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { Badge, Button, ButtonGroup, Col, Image, Row } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Table from "./Table";
import Layout from "../../Layout";
import AdminContext from "../../Services/Admin";
import moment from "moment";
import ItenaryForm from "./Components/ItenaryForm";
import WholeSaleForm from "./Components/WholeSaleForm";
import ReactDatePicker from "react-datepicker";
import SpecificForm from "./Components/SpecificDateForm";
import WYSIWYGEditor from "./Components/Wysiwyg";
import { useDropzone } from "react-dropzone";

const schema = yup
  .object({
    name: yup.string().required(),
  })
  .required();

function ProductsPage(params) {
  const { getAllMaster, loading, addMaster, addMasterForm } =
    useContext(AdminContext);
  const [data, setData] = useState([]);
  const [dataCategory, setDataCategory] = useState([]);
  const [dataBanner, setDataBanner] = useState([]);
  const [dataProvince, setDataProvince] = useState([]);
  const [dataCity, setDataCity] = useState([]);
  const [dataOwner, setDataOwner] = useState([]);
  const [files, setFiles] = useState([]);
  const [filesOld, setFilesOld] = useState([]);
  const [provinceName, setProvinceName] = useState(null);
  const [cityName, setCityName] = useState(null);
  const [dataItenary, setDataItenary] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      limit: 9999,
    },
    status: "none",
  });
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: { "image/*": [] },
    maxFiles: 5,
    maxSize: 5000000,
    onDrop: (acceptedFiles) => {
      setFiles((currentFile) => [
        ...currentFile,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
  });

  const thumbs = files.map((file, index) => (
    <Col xs="4" md="4" lg="4" xl="4" key={index} className="mb-1">
      <div className="d-block position-relative">
        <Image src={file.preview} className="img-input" />
        <Button
          size="sm"
          className="position-absolute img-input-button"
          onClick={() => removeImage(index)}
        >
          X
        </Button>
      </div>
    </Col>
  ));

  const thumbs2 = filesOld.map((file, index) => (
    <Col xs="4" md="4" lg="4" xl="4" key={index} className="mb-1">
      <div className="d-block position-relative">
        <Image
          src={process.env.REACT_APP_FILE_URL + "/" + file}
          className="img-input"
        />
        <Button
          size="sm"
          className="position-absolute img-input-button"
          onClick={() => removeImage2(index)}
        >
          X
        </Button>
      </div>
    </Col>
  ));

  const removeImage = (index) => {
    var array = [...files];
    array.splice(index, 1);
    setFiles(array);
  };

  const removeImage2 = (index) => {
    var array = [...filesOld];
    array.splice(index, 1);
    setFilesOld(array);
  };

  const defaultValues = {
    name: "",
    subName: "",
    description: "",
    shortDescription: "",
    province: "",
    city: "",
    meetingLocation: "",
    longitude: "",
    latitude: "",
    category: "",
    banner: "",
    type: "",
    minPerson: "",
    maxPerson: "",
    minOrder: "",
    maxOrder: "",
    ownerType: "",
    ownerOwner: "",
    crossOutPrice: "",
    price: "",
    duration: "",
    startCampaign: "",
    endCampaign: "",
    priceCampaign: "",
    maxCampaign: "",
    wholeSaleType: "",
    wholeSale: [],
    facilitiesInclude: [
      {
        icon: "ticketIcon.svg",
        value: "",
      },
      {
        icon: "transportIcon.svg",
        value: "",
      },
      {
        icon: "beverageIcon.svg",
        value: "",
      },
      {
        icon: "equipmentIcon.svg",
        value: "",
      },
      {
        icon: "tourGuideIcon.svg",
        value: "",
      },
      {
        icon: "accomodationIcon.svg",
        value: "",
      },
      {
        icon: "campIcon.svg",
        value: "",
      },
      {
        icon: "medicalKitIcon.svg",
        value: "",
      },
      {
        icon: "parkingIcon.svg",
        value: "",
      },
      {
        icon: "documentationIcon.svg",
        value: "",
      },
      {
        icon: "sleepingBagIcon.svg",
        value: "",
      },
      {
        icon: "woodFireIcon.svg",
        value: "",
      },
      {
        icon: "lanternIcon.svg",
        value: "",
      },
      {
        icon: "electricTerminalIcon.svg",
        value: "",
      },
      {
        icon: "souvenirIcon.svg",
        value: "",
      },
      {
        icon: "bbmIcon.svg",
        value: "",
      },
      {
        icon: "othersIcon.svg",
        value: "",
      },
    ],
    facilitiesExclude: "",
    termAndCondition: "",
    cancellationPolicy: "",
    additionalInfo: "",
    scheduleType: "",
    seasonalType: "",
    daysEnabled: "",
    itinerary: [],
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const { fields } = useFieldArray({
    control,
    name: "facilitiesInclude",
  });

  const getParams = (params) => ({
    search: params.pagination?.search,
    page: params.pagination?.current,
    limit: params.pagination?.limit,
    sort: params.sorter?.field,
    direction: params.sorter?.order === "descend" ? "DESC" : "ASC",
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "createdAt",
        header: "Created At",
        Cell: ({ cell }) => moment(cell.getValue()).locale("id").format("LL"),
      },
      {
        accessorKey: "generalInformation.picture",
        header: "Banner",
        Cell: ({ cell }) => {
          if (cell.getValue()?.length > 0) {
            return (
              <Image
                src={`${process.env.REACT_APP_FILE_URL}/${cell.getValue()[0]}`}
                className="img-table"
              />
            );
          }
        },
      },
      {
        accessorKey: "generalInformation.shortDescription",
        header: "Name",
      },
      {
        accessorKey: "generalInformation.subName",
        header: "Sub Name",
      },
      {
        accessorKey: "status.currentStatus",
        header: "Status",
        Cell: ({ cell }) => {
          return <Badge bg={"secondary"}>{cell.getValue()}</Badge>;
        },
      },
      {
        accessorKey: "status.isPublished",
        header: "Is Published",
        Cell: ({ cell }) => {
          let status = "Not Published";
          if (cell.getValue() === true) {
            status = "Published";
          }
          return (
            <Badge bg={status === "Published" ? "success" : "danger"}>
              {status}
            </Badge>
          );
        },
      },
      {
        accessorKey: "pricingDetail.duration",
        header: "Duration",
        Cell: ({ cell }) => `${cell.getValue()} days`,
      },
      {
        accessorKey: "productDetail.tripType",
        header: "Type",
        Cell: ({ cell }) => {
          let status = "Private Trip";
          if (cell.getValue() === "open trip") {
            status = "Open Trip";
          }
          return (
            <Badge bg={status === "Open Trip" ? "success" : "danger"}>
              {status}
            </Badge>
          );
        },
      },
    ],
    []
  );

  const bodyForm = () => {
    return (
      <>
        <div className="mb-3">
          <label htmlFor="productCover" className="form-label">
            Product Image
          </label>
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <i className="bx bx-image fs-1"></i>
            <p>Drag & drop files here, or click to select files</p>
            <small className="text-muted">Max 5 files, 5Mb/file</small>
          </div>
          <Row className="justify-content-center">
            {thumbs} {thumbs2}
          </Row>
        </div>
        <hr></hr>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Product Name
          </label>
          <input
            type="text"
            id="name"
            className="form-control"
            {...register("name")}
            placeholder="Enter your product name"
          />
          <small className="text-danger">{errors.name?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="subName" className="form-label">
            Sub Name
          </label>
          <input
            type="text"
            id="subName"
            className="form-control"
            {...register("subName")}
            placeholder="Enter your product sub name"
          />
          <small className="text-danger">{errors.subName?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Description
          </label>
          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <WYSIWYGEditor onChange={field.onChange} value={field.value} />
            )}
          />
          <small className="text-danger">{errors.description?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="shortDescription" className="form-label">
            Short Description
          </label>
          <textarea
            type="text"
            id="shortDescription"
            className="form-control"
            {...register("shortDescription")}
            placeholder="Enter your product short description"
          />
          <small className="text-danger">
            {errors.shortDescription?.message}
          </small>
        </div>
        <hr></hr>
        <div className="mb-3">
          <label htmlFor="province" className="form-label">
            Province
          </label>
          <Controller
            control={control}
            name="province"
            render={({ field: { onChange, value, ref } }) => (
              <Select
                ref={ref}
                value={dataProvince.find((c) => c.value === value)}
                onChange={(val) => {
                  setProvinceName(val.label);
                  onChange(val.value);
                }}
                options={dataProvince}
                className="form-control p-0 border-0"
              />
            )}
          />
          <small className="text-danger">{errors.province?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="city" className="form-label">
            City
          </label>
          <Controller
            control={control}
            name="city"
            render={({ field: { onChange, value, ref } }) => (
              <Select
                ref={ref}
                value={dataCity.find((c) => c.value === value)}
                onChange={(val) => {
                  setCityName(val.label);
                  onChange(val.value);
                }}
                options={dataCity}
                className="form-control p-0 border-0"
              />
            )}
          />
          <small className="text-danger">{errors.city?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="meetingLocation" className="form-label">
            Meeting Location
          </label>
          <input
            type="text"
            id="meetingLocation"
            className="form-control"
            {...register("meetingLocation")}
            placeholder="Enter your meeting location"
          />
          <small className="text-danger">
            {errors.meetingLocation?.message}
          </small>
        </div>
        <Row>
          <Col xs="12" md="12" lg="6" xl="6">
            <div className="mb-3">
              <label htmlFor="longitude" className="form-label">
                Longitude
              </label>
              <input
                type="text"
                id="longitude"
                className="form-control"
                {...register("longitude")}
                placeholder="Enter your longitude"
              />
              <small className="text-danger">{errors.longitude?.message}</small>
            </div>
          </Col>
          <Col xs="12" md="12" lg="6" xl="6">
            <div className="mb-3">
              <label htmlFor="latitude" className="form-label">
                Latitude
              </label>
              <input
                type="text"
                id="latitude"
                className="form-control"
                {...register("latitude")}
                placeholder="Enter your latitude"
              />
              <small className="text-danger">{errors.latitude?.message}</small>
            </div>
          </Col>
        </Row>
        <div className="mb-3">
          <label htmlFor="category" className="form-label">
            Product Category
          </label>
          <Controller
            control={control}
            name="category"
            render={({ field: { onChange, value, ref } }) => (
              <Select
                ref={ref}
                onChange={onChange}
                options={dataCategory.map((item) => ({
                  value: item.name,
                  label: item.name,
                }))}
                value={value}
                isMulti
                className="form-control p-0 border-0"
              />
            )}
          />
          <small className="text-danger">{errors.category?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="banners" className="form-label">
            Link to Banner
          </label>
          <Controller
            control={control}
            name="banners"
            render={({ field: { onChange, value, ref } }) => (
              <Select
                ref={ref}
                onChange={onChange}
                options={dataBanner.map((item) => ({
                  value: item._id,
                  label: item.name,
                }))}
                value={value}
                isMulti
                className="form-control p-0 border-0"
              />
            )}
          />
          <small className="text-danger">{errors.banners?.message}</small>
        </div>
        <div className="mb-3">
          <label htmlFor="type" className="form-label">
            Trip Type
          </label>
          <select id="type" className="form-select" {...register("type")}>
            <option value="">- Select Trip Type -</option>
            <option value="open trip">Open Trip</option>
            <option value="private trip">Private Trip</option>
          </select>
          <small className="text-danger">{errors.type?.message}</small>
        </div>
        <Row>
          <Col xs="12" md="12" lg="6" xl="6">
            <div className="mb-3">
              <label htmlFor="minPerson" className="form-label">
                Min Person
              </label>
              <input
                type="number"
                id="minPerson"
                className="form-control"
                {...register("minPerson")}
                placeholder="Enter your min person"
              />
              <small className="text-danger">{errors.minPerson?.message}</small>
            </div>
          </Col>
          <Col xs="12" md="12" lg="6" xl="6">
            <div className="mb-3">
              <label htmlFor="maxPerson" className="form-label">
                Max Person
              </label>
              <input
                type="number"
                id="maxPerson"
                className="form-control"
                {...register("maxPerson")}
                placeholder="Enter your max person"
              />
              <small className="text-danger">{errors.maxPerson?.message}</small>
            </div>
          </Col>
        </Row>

        <hr></hr>
        <div className="mb-3">
          <div>
            <label className="form-label">Owner</label>
          </div>
          <div className="form-check form-check-inline mb-3">
            <input
              className="form-check-input"
              type="radio"
              id="owner1"
              value="travel assistance"
              {...register("ownerType")}
            />
            <label className="form-check-label small" htmlFor="owner1">
              Travel Assistance
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              id="owner2"
              value="partners"
              {...register("ownerType")}
            />
            <label className="form-check-label small" htmlFor="owner2">
              Partners
            </label>
          </div>
          <small className="text-danger">{errors.ownerType?.message}</small>

          <Controller
            control={control}
            name={"ownerOwner"}
            render={({ field: { onChange, value, ref } }) => (
              <Select
                ref={ref}
                onChange={onChange}
                options={dataOwner.map((item) => ({
                  value: item._id,
                  label: item.firstName + " " + item.lastName,
                }))}
                value={value}
                className="form-control p-0 border-0"
              />
            )}
          />
          <small className="text-danger">{errors.ownerOwner?.message}</small>
        </div>
        <hr></hr>
        <Row>
          <Col xs="5" md="5" lg="5" xl="5">
            <div className="mb-3">
              <label htmlFor="crossOutPrice" className="form-label">
                Cross Out Price
              </label>
              <input
                type="number"
                id="crossOutPrice"
                className="form-control text-end"
                {...register("crossOutPrice")}
                placeholder="Enter your cross out price"
              />
              <small className="text-danger">
                {errors.crossOutPrice?.message}
              </small>
            </div>
          </Col>
          <Col xs="5" md="5" lg="5" xl="5">
            <div className="mb-3">
              <label htmlFor="price" className="form-label">
                Price
              </label>
              <input
                type="number"
                id="price"
                className="form-control text-end"
                {...register("price")}
                placeholder="Enter your price"
              />
              <small className="text-danger">{errors.price?.message}</small>
            </div>
          </Col>
          <Col xs="2" md="2" lg="2" xl="2">
            <div className="mb-3">
              <label htmlFor="duration" className="form-label">
                Duration
              </label>
              <input
                type="number"
                id="duration"
                className="form-control"
                {...register("duration")}
              />
              <small className="text-danger">{errors.duration?.message}</small>
            </div>
          </Col>
        </Row>
        <hr></hr>
        <Row>
          <Col xs="6" md="6" lg="6" xl="6">
            <div className="mb-3">
              <label htmlFor="startCampaign" className="form-label">
                Start Campaign
              </label>
              <Controller
                control={control}
                name={`startCampaign`}
                render={({ field }) => (
                  <ReactDatePicker
                    placeholderText="Select start date"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    dateFormat="dd/MM/yyyy"
                    selectsStart
                    startDate={field.value}
                    endDate={watch("endCampaign")}
                    minDate={new Date()}
                    className="form-control"
                  />
                )}
              />
              <small className="text-danger">
                {errors.startCampaign?.message}
              </small>
            </div>
          </Col>
          <Col xs="6" md="6" lg="6" xl="6">
            <div className="mb-3">
              <label htmlFor="endCampaign" className="form-label">
                End Campaign
              </label>
              <Controller
                control={control}
                name={`endCampaign`}
                render={({ field }) => (
                  <ReactDatePicker
                    placeholderText="Select end date"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    dateFormat="dd/MM/yyyy"
                    selectsEnd
                    startDate={watch("startCampaign")}
                    endDate={field.value}
                    minDate={new Date()}
                    className="form-control"
                  />
                )}
              />
              <small className="text-danger">
                {errors.endCampaign?.message}
              </small>
            </div>
          </Col>
          <Col xs="8" md="8" lg="8" xl="8">
            <div className="mb-3">
              <label htmlFor="priceCampaign" className="form-label">
                Price Campaign
              </label>
              <input
                type="number"
                id="priceCampaign"
                className="form-control text-end"
                {...register("priceCampaign")}
                placeholder="Enter your price"
              />
              <small className="text-danger">
                {errors.priceCampaign?.message}
              </small>
            </div>
          </Col>
          <Col xs="4" md="4" lg="4" xl="4">
            <div className="mb-3">
              <label htmlFor="maxCampaign" className="form-label">
                Max Pax
              </label>
              <input
                type="number"
                id="maxCampaign"
                className="form-control"
                {...register("maxCampaign")}
              />
              <small className="text-danger">
                {errors.maxCampaign?.message}
              </small>
            </div>
          </Col>
        </Row>
        <hr></hr>
        <div className="mb-3">
          <div>
            <label className="form-label">Discount Types</label>
          </div>
          <div className="form-check form-check-inline mb-3">
            <input
              className="form-check-input"
              type="radio"
              id="wholeSale1"
              value="amount"
              {...register("wholeSaleType")}
            />
            <label className="form-check-label small" htmlFor="wholeSale1">
              Amount
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              id="wholeSale2"
              value="percentage"
              {...register("wholeSaleType")}
            />
            <label className="form-check-label small" htmlFor="wholeSale2">
              Percentage
            </label>
          </div>
          <small className="text-danger">{errors.wholeSaleType?.message}</small>
          <WholeSaleForm {...{ control, register, errors }} />
        </div>
        <hr></hr>
        <div className="mb-3">
          <label htmlFor="facilitiesInclude" className="form-label">
            Facilities Include
          </label>
          {fields.map((item, index) => (
            <Row key={item.id} className="mb-3">
              <Col xs="3" md="2" lg="1" xl="1">
                <input
                  type="hidden"
                  id={"facilitiesInclude" + index + "icon"}
                  className="form-control"
                  {...register(`facilitiesInclude[${index}].icon`)}
                  value={item.icon}
                />
                <Image
                  src={`/assets/icon/${item.icon}`}
                  style={{ maxWidth: "32px" }}
                />
              </Col>
              <Col xs="9" md="10" lg="11" xl="11">
                <input
                  type="text"
                  id={"facilitiesInclude" + index + "value"}
                  className="form-control"
                  {...register(`facilitiesInclude[${index}].value`)}
                  placeholder="(Optional)"
                />
              </Col>
            </Row>
          ))}

          <small className="text-danger">
            {errors.facilitiesInclude?.message}
          </small>
        </div>
        <hr></hr>
        <div className="mb-3">
          <label htmlFor="termAndCondition" className="form-label">
            Term and Condition
          </label>
          <textarea
            type="text"
            id="termAndCondition"
            className="form-control"
            {...register("termAndCondition")}
            placeholder="Enter your product term and condition"
          />
          <small className="text-danger">
            {errors.termAndCondition?.message}
          </small>
        </div>
        <div className="mb-3">
          <label htmlFor="cancellationPolicy" className="form-label">
            Cancellation Policy
          </label>
          <textarea
            type="text"
            id="cancellationPolicy"
            className="form-control"
            {...register("cancellationPolicy")}
            placeholder="Enter your product cancellation policy"
          />
          <small className="text-danger">
            {errors.cancellationPolicy?.message}
          </small>
        </div>
        <div className="mb-3">
          <label htmlFor="additionalInfo" className="form-label">
            Additional Info
          </label>
          <textarea
            type="text"
            id="additionalInfo"
            className="form-control"
            {...register("additionalInfo")}
            placeholder="Enter your additional info"
          />
          <small className="text-danger">
            {errors.additionalInfo?.message}
          </small>
        </div>
        <hr></hr>
        <div className="mb-3">
          <div>
            <label className="form-label">Schedule Types</label>
          </div>
          <div className="form-check form-check-inline mb-3">
            <input
              className="form-check-input"
              type="radio"
              id="schedule1"
              value="seasonal"
              {...register("scheduleType")}
            />
            <label className="form-check-label small" htmlFor="schedule1">
              All Season Product
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              id="schedule2"
              value="specific date"
              {...register("scheduleType")}
            />
            <label className="form-check-label small" htmlFor="schedule2">
              Specific Date Product
            </label>
          </div>
          <small className="text-danger">{errors.scheduleType?.message}</small>
          {watch("scheduleType") === "seasonal" && (
            <div className="mb-3">
              <div>
                <div>
                  <label className="form-label">All Season Type</label>
                </div>
                <div className="form-check form-check-inline mb-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="seasonalSchedule1"
                    value="every week"
                    {...register("seasonalType")}
                  />
                  <label
                    className="form-check-label small"
                    htmlFor="seasonalSchedule1"
                  >
                    Every Week
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="seasonalSchedule2"
                    value="every month"
                    {...register("seasonalType")}
                  />
                  <label
                    className="form-check-label small"
                    htmlFor="seasonalSchedule2"
                  >
                    Every Month
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="seasonalSchedule3"
                    value="every day"
                    {...register("seasonalType")}
                  />
                  <label
                    className="form-check-label small"
                    htmlFor="seasonalSchedule3"
                  >
                    Every Day
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="seasonalSchedule4"
                    value="seasonal"
                    {...register("seasonalType")}
                  />
                  <label
                    className="form-check-label small"
                    htmlFor="seasonalSchedule4"
                  >
                    Seasonal
                  </label>
                </div>
                <small className="text-danger">
                  {errors.seasonalType?.message}
                </small>
              </div>
              {watch("seasonalType") === "every week" && (
                <div>
                  <div>
                    <label htmlFor="daysEnabled" className="form-label">
                      Days Enabled
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="daysEnabled"
                    render={({ field: { onChange, value, ref } }) => (
                      <Select
                        ref={ref}
                        onChange={onChange}
                        options={[
                          {
                            value: "sunday",
                            label: "Sunday",
                          },
                          {
                            value: "monday",
                            label: "Monday",
                          },
                          {
                            value: "tuesday",
                            label: "Tuesday",
                          },
                          {
                            value: "wednesday",
                            label: "Wednesday",
                          },
                          {
                            value: "thrusday",
                            label: "Thrusday",
                          },
                          {
                            value: "friday",
                            label: "Friday",
                          },
                          {
                            value: "saturday",
                            label: "Saturday",
                          },
                        ]}
                        value={value}
                        isMulti
                        className="form-control p-0 border-0"
                      />
                    )}
                  />
                </div>
              )}
              {watch("seasonalType") === "every month" && (
                <div>
                  <div>
                    <label htmlFor="monthEnabled" className="form-label">
                      Month Enabled
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="monthEnabled"
                    render={({ field: { onChange, value, ref } }) => (
                      <Select
                        ref={ref}
                        onChange={onChange}
                        options={[
                          {
                            value: 1,
                            label: "January",
                          },
                          {
                            value: 2,
                            label: "February",
                          },
                          {
                            value: 3,
                            label: "March",
                          },
                          {
                            value: 4,
                            label: "April",
                          },
                          {
                            value: 5,
                            label: "May",
                          },
                          {
                            value: 6,
                            label: "June",
                          },
                          {
                            value: 7,
                            label: "July",
                          },
                          {
                            value: 8,
                            label: "August",
                          },
                          {
                            value: 9,
                            label: "September",
                          },
                          {
                            value: 10,
                            label: "October",
                          },
                          {
                            value: 11,
                            label: "November",
                          },
                          {
                            value: 12,
                            label: "December",
                          },
                        ]}
                        value={value}
                        isMulti
                        className="form-control p-0 border-0"
                      />
                    )}
                  />
                </div>
              )}
              {watch("seasonalType") === "seasonal" && (
                <div className="d-flex align-items-center">
                  <div className="w-50">
                    <label className="form-label">Start Date</label>
                    <Controller
                      control={control}
                      {...register("startDate")}
                      render={({ field }) => (
                        <ReactDatePicker
                          placeholderText="Select start date"
                          onChange={(date) => field.onChange(date)}
                          selected={field.value}
                          dateFormat="dd/MM/yyyy"
                          selectsStart
                          startDate={field.value}
                          endDate={watch("endDate")}
                          minDate={new Date()}
                          className="form-control"
                        />
                      )}
                    />
                  </div>
                  <div className="px-3"></div>
                  <div className="w-50">
                    <label className="form-label">End Date</label>
                    <Controller
                      control={control}
                      {...register("endDate")}
                      render={({ field }) => (
                        <ReactDatePicker
                          placeholderText="Select end date"
                          onChange={(date) => field.onChange(date)}
                          selected={field.value}
                          dateFormat="dd/MM/yyyy"
                          selectsEnd
                          startDate={watch("startDate")}
                          endDate={field.value}
                          minDate={new Date()}
                          className="form-control"
                        />
                      )}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {watch("scheduleType") === "specific date" && (
            <SpecificForm {...{ control, register, errors }} />
          )}
        </div>
        <hr></hr>
        <div className="mb-3">
          <div>
            <label className="form-label">Itenary</label>
          </div>
          <ItenaryForm {...{ control, register, errors }} />
        </div>
      </>
    );
  };

  const getData = async () => {
    const customParams = getParams(tableParams);
    const newData = await addMaster(
      `product/got-byadp?page=${customParams.page}&limit=${customParams.limit}`,
      {
        filter: {
          status: tableParams.status || "none",
          search: customParams.search || "",
        },
      }
    );
    if (newData) {
      setData(newData.data);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: newData?.data?.length,
        },
      });
    }
  };

  const getDataCategory = async () => {
    const newData = await getAllMaster("category", {
      type: "product",
      isDeleted: false,
    });
    if (newData) {
      setDataCategory(newData.data);
    }
  };

  const getDataBanner = async () => {
    const newData = await getAllMaster("banner", {
      type: "adp",
      page: 1,
      limit: 9999999,
    });
    if (newData) {
      setDataBanner(newData.data);
    }
  };

  const getDataOwner = async (type) => {
    if (localStorage.getItem("role") !== "super admin") {
      setDataOwner([
        {
          _id: localStorage.getItem("_id"),
          firstName: localStorage.getItem("firstName"),
          lastName: localStorage.getItem("lastName"),
        },
      ]);
    } else {
      const newType =
        type === "partners"
          ? "partner"
          : type === "travel assistance"
          ? "travelAssistance"
          : null;
      const newData = await getAllMaster("user", {
        type: newType,
        page: 1,
        limit: 99990000,
      });
      if (newData) {
        setDataOwner(newData.data);
      }
    }
  };

  const getDataProvince = async () => {
    const newData = await getAllMaster(`address/province`);
    if (newData) {
      let dataProvinces = [
        {
          value: "",
          label: "- Select Province -",
        },
      ];
      dataProvinces.push(
        ...newData?.data?.map((item) => ({
          value: item.province_id,
          label: item.province,
        }))
      );

      setDataProvince(dataProvinces);
    }
  };

  const getDataCity = async (province_id) => {
    const newData = await getAllMaster(
      `address/city?provinceId=${province_id}`
    );
    if (newData) {
      let dataCitys = [
        {
          value: "",
          label: "- Select City -",
        },
      ];

      dataCitys.push(
        ...newData?.data?.map((item) => ({
          value: item.city_id,
          label: item.city_name,
        }))
      );

      setDataCity(dataCitys);
    }
  };

  useEffect(() => {
    getData();
  }, [
    tableParams.status,
    tableParams.sorter,
    tableParams.pagination.search,
    tableParams.pagination.limit,
    tableParams.pagination.page,
  ]);

  useEffect(() => {
    getDataCategory();
    getDataProvince();
    getDataBanner();
  }, []);

  useEffect(() => {
    watch((value, { name, type }) => {
      if (name === "ownerType" && type === "change") {
        getDataOwner(value.ownerType);
        setValue("ownerOwner", "");
      } else if (name === "province" && type === "change") {
        getValues("city") && setValue("city", "");
        setDataCity([
          {
            value: "",
            label: "- Select City -",
          },
        ]);
        getDataCity(value?.province);
      }
    });
  }, [watch]);

  return (
    <Layout>
      <div className="container-xxl flex-grow-1 container-p-y">
        <div className="nav-align-top mb-4">
          <ul
            className="nav nav-pills mb-3 bg-white p-2 rounded shadow-sm"
            role="tablist"
          >
            <li
              className="nav-item"
              onClick={() =>
                setTableParams({
                  ...tableParams,
                  status: "none",
                })
              }
            >
              <button
                type="button"
                className="nav-link active"
                role="tab"
                data-bs-toggle="tab"
              >
                All
              </button>
            </li>
            <li
              className="nav-item"
              onClick={() =>
                setTableParams({
                  ...tableParams,
                  status: "approved",
                })
              }
            >
              <button
                type="button"
                className="nav-link"
                role="tab"
                data-bs-toggle="tab"
              >
                Approved
              </button>
            </li>
            {/* <li
              className="nav-item"
              onClick={() =>
                setTableParams({
                  ...tableParams,
                  status: "pending",
                })
              }
            >
              <button
                type="button"
                className="nav-link"
                role="tab"
                data-bs-toggle="tab"
              >
                Pending
              </button>
            </li>
            <li
              className="nav-item"
              onClick={() =>
                setTableParams({
                  ...tableParams,
                  status: "processing",
                })
              }
            >
              <button
                type="button"
                className="nav-link"
                role="tab"
                data-bs-toggle="tab"
              >
                Processing
              </button>
            </li> */}
            <li
              className="nav-item"
              onClick={() =>
                setTableParams({
                  ...tableParams,
                  status: "request",
                })
              }
            >
              <button
                type="button"
                className="nav-link"
                role="tab"
                data-bs-toggle="tab"
              >
                Request
              </button>
            </li>
            {/* <li
              className="nav-item"
              onClick={() =>
                setTableParams({
                  ...tableParams,
                  status: "request_delete",
                })
              }
            >
              <button
                type="button"
                className="nav-link"
                role="tab"
                data-bs-toggle="tab"
              >
                Request Delete
              </button>
            </li> */}
            <li
              className="nav-item"
              onClick={() =>
                setTableParams({
                  ...tableParams,
                  status: "request_update",
                })
              }
            >
              <button
                type="button"
                className="nav-link"
                role="tab"
                data-bs-toggle="tab"
              >
                Request Update
              </button>
            </li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane fade show active" role="tabpanel">
              <Table
                columns={columns}
                data={data}
                setFiles={setFiles}
                getDataOwner={getDataOwner}
                setFilesOld={setFilesOld}
                getData={getData}
                getAllMaster={getAllMaster}
                getDataCity={getDataCity}
                addAction={addMaster}
                addActionForm={addMasterForm}
                bodyForm={bodyForm}
                handleSubmit={handleSubmit}
                reset={reset}
                loading={loading}
                files={files}
                filesOld={filesOld}
                provinceName={provinceName}
                cityName={cityName}
                setProvinceName={setProvinceName}
                setCityName={setCityName}
                defaultValues={defaultValues}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ProductsPage;
