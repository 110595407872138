import { Button, Col, Row } from "react-bootstrap";
import { useFieldArray } from "react-hook-form";

export default function WholeSaleForm({ control, register, error }) {
  const { fields, remove, insert } = useFieldArray({
    control,
    name: "wholeSale",
  });

  return (
    <div>
      {fields.map((item, index) => (
        <Row key={item.id} className="align-items-end">
          <Col xs="3" md="3" lg="3" xl="3">
            <div className="mb-3">
              <label htmlFor={"wholeSalePax" + index} className="form-label">
                Pax
              </label>
              <input
                type="number"
                id={"wholeSalePax" + index}
                className="form-control"
                defaultValue={`${item.wholeSalePax}`}
                {...register(`wholeSale[${index}].wholeSalePax`)}
              />
            </div>
          </Col>
          <Col xs="7" md="7" lg="7" xl="7">
            <div className="mb-3">
              <label htmlFor={"wholeSaleTotal" + index} className="form-label">
                Amount
              </label>
              <input
                type="number"
                id={"wholeSaleTotal" + index}
                className="form-control text-end"
                defaultValue={`${item.wholeSaleTotal}`}
                {...register(`wholeSale[${index}].wholeSaleTotal`)}
              />
            </div>
          </Col>

          <Col xs="2" md="2" lg="2" xl="2">
            {index !== 0 && (
              <div className="mb-3">
                <Button variant="danger" onClick={() => remove(index)}>
                  <i className="bx bx-minus-circle"></i>
                </Button>
              </div>
            )}
          </Col>
        </Row>
      ))}
      <Button
        variant="success"
        onClick={() =>
          insert(fields.length, {
            wholeSalePax: "",
            wholeSaleTotal: "",
          })
        }
      >
        <i className="bx bx-plus-circle"></i> WholeSale
      </Button>
    </div>
  );
}
