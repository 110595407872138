import MaterialReactTable from "material-react-table";
import { useEffect, useState } from "react";
import { Button, Modal, Stack } from "react-bootstrap";
import { PublishedWithChanges, Edit } from "@mui/icons-material";
import { toast } from "react-toastify";
import moment from "moment";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    publishedPrice: yup.string().required(),
  })
  .required();

function Table(params) {
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });
  const [filesOld, setFilesOld] = useState([]);

  const [modal, setModal] = useState({
    show: false,
    id: "",
    type: "",
    data: {},
  });

  const handleClose = () => {
    setModal({ show: false, id: "", type: "", data: {} });
    params.reset(params.defaultValues);
    params.setFiles([]);
    params.setFilesOld([]);
  };
  const handleShow = (id, type, data) =>
    setModal({ show: true, id: id, type: type, data: data });

  const deleteAction = async () => {
    const res = await params.addAction("product/delete/" + modal?.data?._id);
    if (!res?.err) {
      params.getData();
      toast.success("Delete Data Success");
      handleClose();
    }
  };

  const addAction = async (values, type) => {
    const itinerary = values.itinerary?.map((item, index) => ({
      day: item.itineraryDay,
      activity: item.itineraryDetail.map((subItem, subIndex) => ({
        time: subItem.itineraryTime,
        description: subItem.itineraryDescription,
      })),
    }));

    const wholeSale = values.wholeSale?.map((item, index) => ({
      type: values.wholeSaleType,
      pax: item.wholeSalePax,
      total: item.wholeSaleTotal,
    }));

    const specificDate = values.specific?.map((item, index) =>
      moment(item.date).format("DD/MM/YYYY")
    );

    const daysEnabled =
      (values.daysEnabled &&
        values.daysEnabled?.map((item, index) => item.value)) ||
      [];

    const monthEnabled =
      (values.monthEnabled &&
        values.monthEnabled?.map((item, index) => parseInt(item.value))) ||
      [];

    let pricingDetail = {
      crossOutPrice: values.crossOutPrice,
      price: values.price,
      duration: values.duration,
    };

    if (
      values.priceCampaign &&
      values.maxCampaign &&
      values.startCampaign &&
      values.endCampaign
    ) {
      pricingDetail = {
        crossOutPrice: values.crossOutPrice,
        price: values.price,
        duration: values.duration,
        campaign: {
          startDate: moment(values.startCampaign).format("YYYY-MM-DD"),
          endDate: moment(values.endCampaign).format("YYYY-MM-DD"),
          maxPax: values.maxCampaign,
          total: values.priceCampaign,
        },
      };
    }

    const bodyRequest = {
      productType: "local experience",
      generalInformation: {
        name: values.name,
        subName: values.subName,
        description: values.description,
        shortDescription: values.shortDescription,
      },
      productDetail: {
        province: params.provinceName,
        city: params.cityName,
        provinceId: values.province,
        cityId: values.city,
        lat: values.latitude,
        long: values.longitude,
        meetingLocation: values.meetingLocation,
        categories: values.category?.map((a) => a.value),
        tripType: values.type,
        minPerson: values.minPerson,
        maxPerson: values.maxPerson,
      },
      owner: {
        type: values.ownerType,
        owner: values.ownerOwner?.value,
      },
      pricingDetail: pricingDetail,
      wholeSale: wholeSale,
      facilities: {
        include: values.facilitiesInclude,
        exclude: [{ icon: "-", value: "-" }],
      },
      additionalInfo: {
        termAndCondition: values.termAndCondition.split(";").filter((item) => {
          return item !== "";
        }),
        cancellationPolicy: values.cancellationPolicy
          .split(";")
          .filter((item) => {
            return item !== "";
          }),
        additionalInfo: values.additionalInfo.split(";").filter((item) => {
          return item !== "";
        }),
      },
      scheduleType: {
        type: values.scheduleType,
        allSeasonType:
          values.scheduleType === "specific date"
            ? values.scheduleType
            : values.seasonalType,
        schedule:
          (values.scheduleType === "specific date" && specificDate) || [],
        days:
          (values.seasonalType === "every week" &&
            values.scheduleType === "seasonal" &&
            daysEnabled) ||
          [],
        date:
          (values.seasonalType === "every month" &&
            values.scheduleType === "seasonal" &&
            monthEnabled) ||
          [],
        startDate:
          (values.seasonalType === "seasonal" &&
            values.scheduleType === "seasonal" &&
            moment(values.startDate)) ||
          "",
        endDate:
          (values.seasonalType === "seasonal" &&
            values.scheduleType === "seasonal" &&
            moment(values.endDate)) ||
          "",
      },
      itinerary: itinerary,
      banners: values.banners?.map((a) => ({
        _id: a.value,
        link: a.label,
      })),
    };

    let oldImage = [];
    if (type === "Update") {
      bodyRequest._id = values._id;
      oldImage = filesOld.filter((i) => !params.filesOld.includes(i));
    }

    console.log(bodyRequest);

    let response = await params.addAction("product/create", bodyRequest);
    if (!response?.err) {
      if (oldImage?.length > 0) {
        await removeAction(response?.data?._id, oldImage);
      }
      if (params.files?.length > 0) {
        await uploadAction(response?.data?._id);
      }
      params.getData();
      toast.success(type + " Data Success");
      handleClose();
    }
  };

  const uploadAction = async (_id) => {
    const formData = new FormData();
    params.files &&
      params.files.map((item, index) => formData.append("pictures", item));

    let response = await params.addActionForm(
      "product/upload-pictures/" + _id,
      formData
    );
  };

  const removeAction = async (_id, data) => {
    let response = await params.addActionForm(
      "product/delete-pictures/" + _id,
      { pictures: data }
    );
  };

  const updateStatusAction = async (values) => {
    values.publishedPrice = parseInt(values.publishedPrice);
    let response = await params.addAction(
      "product/update-status/" + modal?.data?._id,
      values
    );
    if (!response?.err) {
      params.getData();
      toast.success("Update Status Success");
      handleClose();
    }
  };

  useEffect(() => {
    if (modal.type === "update" && modal.data) {
      const oldData = modal.data;
      params.getDataOwner(oldData?.owner?.type);
      params.getDataCity(oldData?.productDetail?.provinceId);
      params.setProvinceName(oldData?.productDetail?.province);
      params.setCityName(oldData?.productDetail?.city);
      const bodyRequest = {
        _id: oldData?._id,
        name: oldData?.generalInformation?.name,
        subName: oldData?.generalInformation?.subName,
        description: oldData?.generalInformation?.description,
        shortDescription: oldData?.generalInformation?.shortDescription,
        province: oldData?.productDetail?.provinceId,
        city: oldData?.productDetail?.cityId,
        provinceId: oldData?.productDetail?.provinceId,
        cityId: oldData?.productDetail?.cityId,
        minPerson: oldData?.productDetail?.minPerson,
        maxPerson: oldData?.productDetail?.maxPerson,
        meetingLocation: oldData?.productDetail?.meetingLocation,
        longitude: oldData?.productDetail?.long,
        latitude: oldData?.productDetail?.lat,
        type: oldData?.productDetail?.tripType,
        category: oldData?.productDetail?.categories?.map((item) => ({
          label: item,
          value: item,
        })),
        ownerType: oldData?.owner?.type,
        ownerOwner: {
          label:
            oldData?.owner?.owner?.firstName +
            " " +
            oldData?.owner?.owner?.lastName,
          value: oldData?.owner?.owner?._id,
        },
        crossOutPrice: oldData?.pricingDetail?.crossOutPrice,
        price: oldData?.pricingDetail?.price,
        duration: oldData?.pricingDetail?.duration,
        startCampaign: oldData?.pricingDetail?.campaign?.startDate
          ? moment(oldData?.pricingDetail?.campaign?.startDate).toDate()
          : "",
        endCampaign: oldData?.pricingDetail?.campaign?.endDate
          ? moment(oldData?.pricingDetail?.campaign?.endDate).toDate()
          : "",
        priceCampaign: oldData?.pricingDetail?.campaign?.total,
        maxCampaign: oldData?.pricingDetail?.campaign?.maxPax,
        wholeSaleType:
          oldData?.wholeSale?.length > 0 && oldData?.wholeSale[0].type,
        wholeSale: oldData?.wholeSale?.map((item) => ({
          wholeSalePax: item.pax,
          wholeSaleTotal: item.total,
        })),
        facilitiesInclude: oldData?.facilities?.include,
        facilitiesExclude: oldData?.facilities?.exclude,
        additionalInfo: oldData?.additionalInfo?.additionalInfo?.join(";"),
        cancellationPolicy:
          oldData?.additionalInfo?.cancellationPolicy?.join(";"),
        termAndCondition: oldData?.additionalInfo?.termAndCondition?.join(";"),
        scheduleType: oldData?.scheduleType?.type,
        seasonalType: oldData?.scheduleType?.allSeasonType,
        daysEnabled: oldData?.scheduleType?.days?.map((item) => ({
          label: item,
          value: item,
        })),
        monthEnabled: oldData?.scheduleType?.date?.map((item) => ({
          label: item,
          value: item,
        })),
        startDate: oldData?.scheduleType?.startDate
          ? moment(oldData?.scheduleType?.startDate).toDate()
          : moment().toDate(),
        endDate: oldData?.scheduleType?.startDate
          ? moment(oldData?.scheduleType?.endDate).toDate()
          : moment().toDate(),
        specific: oldData?.scheduleType?.schedule?.map((item, index) => ({
          date: moment(item, "DD/MM/YYYY").toDate(),
        })),
        itinerary: oldData?.itinerary?.map((item) => ({
          itineraryDay: item.day,
          itineraryDetail: item?.activity?.map((subItem) => ({
            itineraryTime: subItem.time,
            itineraryDescription: subItem.description,
          })),
        })),
        banners: oldData?.banners?.map((item) => ({
          value: item._id,
          label: item.link,
        })),
      };
      params.reset(bodyRequest);
      params.setFilesOld(oldData?.generalInformation?.picture);
      setFilesOld(oldData?.generalInformation?.picture);
    }
  }, [modal.type]);

  return (
    <>
      <MaterialReactTable
        muiTablePaperProps={{
          sx: {
            boxShadow: "none",
            borderRadius: "1rem",
          },
        }}
        muiTopToolbarProps={{
          sx: {
            borderRadius: "1rem",
          },
        }}
        muiBottomToolbarProps={{
          sx: {
            borderRadius: "1rem",
          },
        }}
        enableRowActions
        positionActionsColumn={"last"}
        renderRowActions={(row, index) => (
          <Stack direction="horizontal" gap={2}>
            <Button
              size="sm"
              variant="warning"
              className="py-1 px-2"
              onClick={() =>
                handleShow(row?.row?.id, "update", row?.row?.original)
              }
            >
              <Edit sx={{ fontSize: 14 }} />
            </Button>
            {/* <Button
              size="sm"
              variant="danger"
              className="py-1 px-2"
              onClick={() =>
                handleShow(row?.row?.id, "delete", row?.row?.original)
              }
            >
              <Delete sx={{ fontSize: 14 }} />
            </Button> */}

            {localStorage.getItem("role") === "super admin" && (
              <Button
                size="sm"
                variant="danger"
                className="py-1 px-2"
                onClick={() => {
                  setValue(
                    "publishedPrice",
                    row?.row?.original?.pricingDetail?.publishedPrice
                  );
                  setValue("status", row?.row?.original?.status?.currentStatus);
                  setValue(
                    "isPublished",
                    row?.row?.original?.status?.isPublished
                  );
                  handleShow(row?.row?.id, "status", row?.row?.original);
                }}
              >
                <PublishedWithChanges sx={{ fontSize: 14 }} />
              </Button>
            )}
          </Stack>
        )}
        state={{
          isLoading: params.loading,
        }}
        getRowId={(originalRow) => originalRow._id}
        renderTopToolbarCustomActions={({ table }) => (
          <Button
            variant="primary"
            onClick={() => {
              handleShow(null, "add");
            }}
            className="m-2"
          >
            Add New
          </Button>
        )}
        columns={params.columns}
        data={params.data}
      />
      {/* <Modal
        show={modal.show && modal.type === "delete"}
        onHide={handleClose}
        centered
      >
        <Modal.Body className="h5 pb-1">
          Are you sure you want to delete this data?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={deleteAction}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal> */}
      <Modal
        show={modal.show && modal.type === "add"}
        onHide={handleClose}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="h5">{params.bodyForm()}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={params.handleSubmit((data) => addAction(data, "Add"))}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modal.show && modal.type === "update"}
        onHide={handleClose}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="h5">{params.bodyForm()}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={params.handleSubmit((data) => addAction(data, "Update"))}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modal.show && modal.type === "status"}
        onHide={handleClose}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Status
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="h5">
          <div className="mb-3">
            <label htmlFor="publishedPrice" className="form-label">
              Published Price
            </label>
            <input
              type="number"
              id="publishedPrice"
              className="form-control"
              {...register("publishedPrice")}
              placeholder="Enter published price"
            />
            <small className="text-danger">
              {errors.publishedPrice?.message}
            </small>
          </div>
          <div className="mb-3">
            <label htmlFor="status" className="form-label">
              Status
            </label>
            <select id="status" className="form-select" {...register("status")}>
              <option value="">- Select Status -</option>
              <option value="request">Request</option>
              <option value="request_update">Request Update</option>
              <option value="request_delete">Request Delete</option>
              <option value="pending">Pending</option>
              <option value="processing">Processing</option>
              <option value="rejected">Rejected</option>
              <option value="approved">Approved</option>
            </select>
            <small className="text-danger">{errors.status?.message}</small>
          </div>
          <div className="mb-3">
            <label htmlFor="isPublished" className="form-label">
              Is Published?
            </label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="isPublished"
                value="true"
                style={{ minWidth: "50px" }}
                {...register("isPublished")}
              />
              <label
                className="form-check-label small ps-2"
                htmlFor="isPublished"
              >
                Publish
              </label>
            </div>
            <small className="text-danger">{errors.isPublished?.message}</small>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit(updateStatusAction)}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Table;
