import MaterialReactTable from "material-react-table";
import { useEffect, useState } from "react";
import { Button, Modal, Stack } from "react-bootstrap";
import { Delete, Edit } from "@mui/icons-material";
import { toast } from "react-toastify";
import moment from "moment";

function makeChar() {
  let result1 = "";
  let result2 = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const number = "1234567890";
  const charactersLength = characters.length;
  const numberLength = number.length;
  let counter = 0;
  while (counter < 4) {
    result1 += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  counter = 0;
  while (counter < 4) {
    result2 += number.charAt(Math.floor(Math.random() * numberLength));
    counter += 1;
  }
  console.log(result1 + result2);
  return result1 + result2;
}

function Table(params) {
  const [modal, setModal] = useState({
    show: false,
    id: "",
    type: "",
    data: {},
  });

  const handleClose = () => {
    setModal({ show: false, id: "", type: "", data: {} });
    params.reset({
      code: "",
      discount: "",
      voucherType: "",
      startDate: "",
      endDate: "",
      isActive: "",
    });
  };
  const handleShow = (id, type, data) =>
    setModal({ show: true, id: id, type: type, data: data });

  const deleteAction = async () => {
    let bodyRequest = modal?.data;
    bodyRequest.voucherId = bodyRequest?._id;
    bodyRequest.isActive = false;
    const res = await params.addAction(
      "voucher/set-active/",
      modal.data
    );
    if (!res?.err) {
      params.getData();
      toast.success("Delete Data Success");
      handleClose();
    }
  };

  const addAction = async (values) => {
    const bodyRequest = {
      code: values.code,
      voucherType: values.voucherType,
      discount: parseInt(values.discount),
      startDate: moment(values.startDate).format("YYYY-MM-DD"),
      endDate: moment(values.endDate).format("YYYY-MM-DD"),
      isActive: values.isActive?true:false,
    };
    let response = await params.addAction("voucher/create", bodyRequest);
    if (!response?.err) {
      params.getData();
      toast.success("Add Data Success");
      handleClose();
    }
  };

  const updateAction = async (values) => {
    const bodyRequest = {
      name: values.name,
      isDeleted: !values.isDeleted,
      type: "partner",
    };
    let response = await params.addAction(
      "voucher/update/" + modal?.data?._id,
      bodyRequest
    );
    if (!response?.err) {
      params.getData();
      toast.success("Update Data Success");
      handleClose();
    }
  };

  useEffect(() => {
    if (modal.type === "update" && modal.data) {
      const oldData = modal.data;
      const bodyRequest = {
        code: oldData?.code,
        voucherType: oldData?.voucherType,
        discount: oldData?.discount,
        startDate: oldData?.startDate
          ? moment(oldData?.startDate).toDate()
          : "",
        endDate: oldData?.endDate ? moment(oldData?.endDate).toDate() : "",
        isActive: oldData?.isActive,
      };
      params.reset(bodyRequest);
    }
  }, [modal.type]);

  return (
    <>
      <MaterialReactTable
        muiTablePaperProps={{
          sx: {
            boxShadow: "none",
            borderRadius: "1rem",
          },
        }}
        muiTopToolbarProps={{
          sx: {
            borderRadius: "1rem",
          },
        }}
        muiBottomToolbarProps={{
          sx: {
            borderRadius: "1rem",
          },
        }}
        enableRowActions
        positionActionsColumn={"last"}
        renderRowActions={(row, index) => (
          <Stack direction="horizontal" gap={2}>
            {/* <Button
              size="sm"
              variant="warning"
              className="py-1 px-2"
              onClick={() =>
                handleShow(row?.row?.id, "update", row?.row?.original)
              }
            >
              <Edit sx={{ fontSize: 14 }} />
            </Button> */}
            <Button
              size="sm"
              variant="danger"
              className="py-1 px-2"
              onClick={() =>
                handleShow(row?.row?.id, "delete", row?.row?.original)
              }
            >
              <Delete sx={{ fontSize: 14 }} />
            </Button>
          </Stack>
        )}
        state={{
          isLoading: params.loading,
        }}
        getRowId={(originalRow) => originalRow._id}
        renderTopToolbarCustomActions={({ table }) => (
          <Button
            variant="primary"
            onClick={() => {
              params.reset({
                code: makeChar(),
                discount: "",
                voucherType: "",
                startDate: "",
                endDate: "",
                isActive: "",
              });
              handleShow(null, "add");
            }}
            className="m-2"
          >
            Add New
          </Button>
        )}
        columns={params.columns}
        data={params.data}
      />
      <Modal
        show={modal.show && modal.type === "delete"}
        onHide={handleClose}
        centered
      >
        <Modal.Body className="h5 pb-1">
          Are you sure you want to delete this data?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={deleteAction}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={modal.show && modal.type === "add"}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="h5">{params.bodyForm()}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={params.handleSubmit(addAction)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modal.show && modal.type === "update"}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="h5">{params.bodyForm()}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={params.handleSubmit(updateAction)}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Table;
