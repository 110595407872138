import MaterialReactTable from "material-react-table";
import { useEffect, useState } from "react";
import { Button, Modal, Stack } from "react-bootstrap";
import { Delete, Edit } from "@mui/icons-material";

function Table(params) {
  const [modal, setModal] = useState({
    show: false,
    id: "",
    type: "",
    data: {},
  });

  const handleClose = () => {
    setModal({ show: false, id: "", type: "", data: {} });
    params.reset({
      salutation: "",
      firstName: "",
      lastName: "",
      email: "",
      countryCode: "",
      phone: "",
      isApproved: "",
      role: "",
      password: "",
      retypePassword: "",
    });
  };
  const handleShow = (id, type, data) =>
    setModal({ show: true, id: id, type: type, data: data });

  const deleteAction = async () => {
    let bodyRequest = modal?.data;
    bodyRequest.isApproved = false;
    const res = await params.addAction("admin/update", modal.data);
    if (!res?.err) {
      params.getData();
      handleClose();
    }
  };

  const addAction = async (values) => {
    const bodyRequest = {
      salutation: values.salutation,
      firstName: values.firstName,
      lastName: values.lastName,
      contact: {
        email: values.email,
        countryCode: values.countryCode,
        phone: values.phone,
      },
      isApproved: values.isApproved,
      role: values.role,
      password: values.password,
    };
    let response = await params.addAction(
      "admin/register",
      bodyRequest
    );
    if (!response?.err) {
      params.getData();
      handleClose();
    }
  };

  const updateAction = async (values) => {
    const bodyRequest = {
      _id: modal?.data?._id,
      salutation: values.salutation,
      firstName: values.firstName,
      lastName: values.lastName,
      contact: {
        email: values.email,
        countryCode: values.countryCode,
        phone: values.phone,
      },
      isApproved: values.isApproved,
      role: values.role,
      password: values.password,
    };
    let response = await params.addAction("admin/update", bodyRequest);
    if (!response?.err) {
      params.getData();
      handleClose();
    }
  };

  useEffect(() => {
    if (modal.type === "update" && modal.data) {
      const oldData = modal.data;
      const bodyRequest = {
        salutation: oldData?.salutation,
        firstName: oldData?.firstName,
        lastName: oldData?.lastName,
        email: oldData?.contact?.email,
        countryCode: oldData?.contact?.countryCode,
        phone: oldData?.contact?.phone,
        isApproved: oldData?.isApproved,
        role: oldData?.role,
      };
      params.reset(bodyRequest);
    }
  }, [modal.type]);

  return (
    <>
      <MaterialReactTable
        muiTablePaperProps={{
          sx: {
            boxShadow: "none",
            borderRadius: "1rem",
          },
        }}
        muiTopToolbarProps={{
          sx: {
            borderRadius: "1rem",
          },
        }}
        muiBottomToolbarProps={{
          sx: {
            borderRadius: "1rem",
          },
        }}
        enableRowActions
        positionActionsColumn={"last"}
        renderRowActions={(row, index) => (
          <Stack direction="horizontal" gap={2}>
            <Button
              size="sm"
              variant="warning"
              className="py-1 px-2"
              onClick={() =>
                handleShow(row?.row?.id, "update", row?.row?.original)
              }
            >
              <Edit sx={{ fontSize: 14 }} />
            </Button>
            <Button
              size="sm"
              variant="danger"
              className="py-1 px-2"
              onClick={() => handleShow(row?.row?.id, "delete")}
            >
              <Delete sx={{ fontSize: 14 }} />
            </Button>
          </Stack>
        )}
        state={{
          isLoading: params.loading,
        }}
        getRowId={(originalRow) => originalRow._id}
        renderTopToolbarCustomActions={({ table }) => (
          <Button
            variant="primary"
            onClick={() => {
              handleShow(null, "add");
            }}
            className="m-2"
          >
            Add New
          </Button>
        )}
        columns={params.columns}
        data={params.data}
      />
      <Modal
        show={modal.show && modal.type === "delete"}
        onHide={handleClose}
        centered
      >
        <Modal.Body className="h5 pb-1">
          Are you sure you want to delete this data?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={deleteAction}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={modal.show && modal.type === "add"}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="h5">{params.bodyForm()}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={params.handleSubmit(addAction)}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modal.show && modal.type === "update"}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="h5">{params.bodyForm()}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={params.handleSubmit(updateAction)}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Table;
